<div
  *ngIf="index !== 0 && !optional"
  class="segment__connector"
></div>

<div
  class="segment"
  [class.segment--first]="index === 0"
  [class.segment--optional]="optional"
>
<div
  class="segment__header"
  (click)="toggle()"
  #segmentHeader
>
  <settings-icon
    *ngIf="!optional"
    class="my-1 mr-4"
    [settingsIconType]="settingsIconType"
    [settingsIconIndex]="index"
    data-testid="settingsIcon"
  ></settings-icon>

  <div
    *ngIf="optional"
    class="segment__custom-icon"
  >
    <svg-icon
      *ngIf="customIcon"
      [svgIcon]="customIcon"
    ></svg-icon>
  </div>

  <div>
    <div class="text-h2 d-flex align-items-center">
      <span>{{ name }}</span>
      <settings-icon
        *ngIf="optional && !settings.valid"
        class="ml-3"
        [settingsIconType]="'invalid'"
      ></settings-icon>
    </div>
    <div *ngIf="description" class="text-muted">{{ description }}</div>
  </div>

  <div class="ml-auto">
    <div *ngIf="state !== State.SELECTED">
      <svg-icon
        class="icon"
        [svgName]="'chevron_down'"
      ></svg-icon>
    </div>

    <div *ngIf="state === State.SELECTED">
      <svg-icon
        class="icon"
        [svgName]="'chevron_up'"
      ></svg-icon>
    </div>
  </div>
</div>


<div
  class="segment__content"
  [hidden]="state !== State.SELECTED"
>
  <ng-content></ng-content>
</div>

<div
  *ngIf="!hideNext"
  class="segment__footer"
  [ngClass]="{
    'd-none': state !== State.SELECTED
  }"
>
  <div
    class="btn btn--primary"
    (click)="selectNextStep()"
  >
    <span i18n data-testid="settingsSegmentContinueButton">Continue</span>
  </div>
</div>
</div>
