import { Component, Input } from '@angular/core';
import { ContactForm } from '../models/ContactForm';
import { Scope } from 'utils/ui-components/scope-switcher/scope-switcher.component';
import { View } from 'contactForm/contact-form-page/contact-form-page.component';

export const ContactFormPageScope = Object.freeze({
  FORM_PAGE: new Scope('formPage', $localize `Form page`),
  SUCCESS_PAGE: new Scope('succesSPage', $localize `Success page`),
});

@Component({
  selector: 'contact-form-preview',
  templateUrl: './contact-form-preview.component.html',
})
export class ContactFormPreviewComponent {
  @Input() contactForm : ContactForm;
  @Input() hideScopeSwitcher = false;
  @Input() scope = ContactFormPageScope.FORM_PAGE;
  @Input() hideRecaptcha = false;

  public readonly scopes = [ContactFormPageScope.FORM_PAGE, ContactFormPageScope.SUCCESS_PAGE];

  constructor(
  ) {}


  get contactFormPageView(): View {
    if(this.scope === ContactFormPageScope.FORM_PAGE) {
      return 'form';
    } else {
      return 'success';
    }
  }
}
