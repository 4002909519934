<div
  class="contact-form-page"
  [style]="style"
  data-testid="contactFormPageDiv"
>
  <div class="header">
    <div class="container container--fullwidth d-flex">
      <logo
        *ngIf="shouldShowLogo"
        utmSource="contact_form"
        segmentSource="contact_form"
        [logoScale]="1"
        class="header__logo"
        data-testid="headerLogo"
      ></logo>
    </div>
  </div>

  <div class="flex-1 container container--sm d-flex flex-column">
    <ng-container *ngIf="view ==='form'">
      <contact-form-form
        [contactForm]="contactForm"
        [customization]="customization"
        [errors]="errors"
        [disabled]="isSubmitting"
        [readonly]="readonly"
        [hideRecaptcha]="hideRecaptcha"
        [isEmbedded]="isEmbedded"
        [shouldShowPoweredBy]="shouldShowPoweredByOnForm"
        (submitForm)="submit($event)"
      ></contact-form-form>
    </ng-container>


    <ng-container *ngIf="view === 'success'">
      <h1 class="text-center">
        <span class="contact-form-page__success-title" data-testid="contactFormSuccesTitle">{{ customization.successTitle }}</span>
      </h1>

      <contact-form-success
        [customization]="customization"
        [contactForm]="contactForm"
        [shouldShowPoweredBy]="shouldShowPoweredByOnForm"
      ></contact-form-success>
    </ng-container>
  </div>
</div>
