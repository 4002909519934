<form [formGroup]="formGroup">
  <div formGroupName="teamleaderMappingConfig">
    <div class="mb-4">
      <div class="d-flex align-items-center mb-2">
        <div i18n class="mr-2">Deal assignee</div>
        <div *ngIf="requestUserService.isFree">
          <pro-feature-badge
            class="select"
            featureText="Select deal assignee"
            i18n-featureText
          >
          </pro-feature-badge>
        </div>
        <combo-box
          *ngIf="!requestUserService.isFree"
          [disabled]="!instance.teamleaderMappingConfig?.createDeal"
          formControlName="dealOwner"
          [options]="dealOwnerOptions"
        ></combo-box>
      </div>
      <div class="text-muted" *ngIf="!instance.teamleaderMappingConfig?.createDeal && !requestUserService.isFree" i18n>
        This form is not configured to create a deal. Choose to create a deal in step 1 to assign a deal assignee.
      </div>
    </div>

    <div>
      <div class="d-flex align-items-center mb-2">
        <div i18n class="mr-2">Deal pipeline</div>
        <div *ngIf="requestUserService.isFree">
          <pro-feature-badge
            class="select"
            featureText="Select a pipeline"
            i18n-featureText
          >
          </pro-feature-badge>
        </div>
        <combo-box
          *ngIf="!requestUserService.isFree"
          [disabled]="!instance.teamleaderMappingConfig?.createDeal"
          formControlName="dealPipelineId"
          [options]="dealPipelineOptions"
        ></combo-box>
      </div>
      <div class="text-muted" *ngIf="!instance.teamleaderMappingConfig?.createDeal && !requestUserService.isFree" i18n>
        This form is not configured to create a deal. Choose to create a deal in step 1 to assign a deal pipeline.
      </div>
      <div class="text-muted"  *ngIf="instance.teamleaderMappingConfig?.createDeal && requestUserService.isFree" i18n>
        Deals will be created in the default pipeline.
      </div>
    </div>
  </div>
</form>

<span class="d-flex flex-row align-items-center mt-4 mb-4">
  <span i18n class="text-h3">Prefill fields with a default value</span>
  <svg-icon
    class="icon icon--sm clickable ml-1"
    [svgName]="'info_badged'"
    [svgStyle]="'filled'"
    [svgSize]="14"

    [modalTriggerFor]="tipsModal"
    [modalClass]="'modal--sm'"
    [modalIcon]="{name: 'info_badged'}"
    modalTitle="Send prefilled answers to Teamleader Focus"
    i18n-modalTitle
  ></svg-icon>
</span>

<div>
  <div *ngFor="let answer of interfacePrefilledAnswers; let i = index">
    <div class="d-flex flex-row align-items-center">
      <svg-icon class="btn__icon" [svgIcon]="entityIcons[answer.mappingField.entity]"></svg-icon>
      <span class="mt-1">
        {{ entityLabels[answer.mappingField.entity] }} - {{ answer.mappingField.name }}: {{ answerString(answer.instance) }}
      </span>
      <svg-icon class="icon icon--sm clickable ml-1 mt-2" (click)="removeAnswer(i)" [svgSize]="14" [svgName]="'close'"></svg-icon>
      <div class="mr-2" [withSettingsErrors]="getErrors(i)"></div>
    </div>
  </div>

  <div *ngIf="!currentQuestion" (click)="addQuestion()" class="btn btn-primary">
    <svg-icon class="btn__icon" [svgName]="'add'"></svg-icon>
    <span i18n>Add a field</span>
  </div>
</div>

<ng-template #modalConfigQuestion>
  <div class="d-flex flex-column mt-2" *ngIf="currentQuestion">
    <div class="d-flex flex-row" >
      <div *ngIf="!selectedMappingField" class="flex-1">
        <div class="label d-flex align-items-center">
          <svg-icon [svgPath]="'illustrations__18x18_teamleader_badge'" class="d-inline-flex"></svg-icon>
          <span class="ml-2" i18n>Link answer to Teamleader Focus</span>
        </div>

        <combo-box
          [(ngModel)]="selectedMappingField"
          [options]="teamleaderMappingFieldComboBoxCategories"
          (optionPicked)="setQuestionType($event)"
        ></combo-box>
      </div>
      <div *ngIf="selectedMappingField" class="flex-1">
        <contact-form-question
          [question]="currentQuestion.question"
          [answer]="currentQuestion.answer"
        ></contact-form-question>
      </div>
    </div>
    <div class="d-flex align-right mt-2">
      <div #cancelButton (click)="cancelQuestion(cancelButton)" class="btn btn--secondary ml-auto mr-2" i18n>Cancel</div>
      <div
        #closeButton
        (click)="canSubmitPrefilledAnswer && submitQuestion(closeButton)"
        class="btn btn--primary mr-2"
        [ngClass]="{'disabled': !canSubmitPrefilledAnswer }"
        [attr.disabled]="!canSubmitPrefilledAnswer"
        i18n
      >
        Submit
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tipsModal>
  <h5 i18n>How prefilled answers work</h5>
  <div class="d-flex flex-column-reverse flex-sm-row">
    <div class="flex-1">
      <ol>
        <li i18n>Add a prefilled answer to a contact form e.g. Contact - Phone number.</li>
        <li i18n>The prefilled answers are hidden to the end-user. The end-user fills in the contact form and submits the answers.</li>
        <li i18n>Teamleader Focus creates a new contact and adds the phone number to this user.</li>
      </ol>
    </div>
    <div class="flex-1">
      <img [src]="urlService.images.questionMappingHelp" />
    </div>
  </div>

  <div class="d-flex justify-content-end mt-6">
    <div class="btn btn--primary" [modalClose]>
      <svg-icon class="btn__icon" [svgName]="'checkmark'"></svg-icon>
      <span i18n>Ok, got it</span>
    </div>
  </div>
</ng-template>
