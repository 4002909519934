import { bind, EventEmitter, logger } from 'utils/util';
import Tile from '../../../components/tiles/Tile';
import { StreamType } from  'meeting/meeting-room/stream';
import Session from '../../users/Session';
import Janus from './Janus';
import JanusHandle from './JanusHandle';
import JanusMaster from './JanusMaster';
import JanusPublisher from './JanusPublisher';
import JanusSubscriber from './JanusSubscriber';


export default class JanusHandleManager {
  static get $inject() {
    return [
      'apiService',
      'meetingService',
      'notificationService',
      'rtcConfigurationService',
      'settingsService',
      'signalStrengthService',
      'siteService',
      'tileService',
      'userService',
    ];
  }

  constructor(
    apiService,
    meetingService,
    notificationService,
    rtcConfigurationService,
    settingsService,
    signalStrengthService,
    siteService,
    tileService,
    userService,
    mediaserverUrl
  ) {
    bind(this);
    EventEmitter.setup(this, ['track', 'connected', 'stats']);

    this.apiService = apiService;
    this.meetingService = meetingService;
    this.notificationService = notificationService;
    this.rtcConfigurationService = rtcConfigurationService;
    this.settingsService = settingsService;
    this.signalStrengthService = signalStrengthService;
    this.siteService = siteService;
    this.tileService = tileService;
    this.userService = userService;

    this.mediaserverUrl = mediaserverUrl;

    this.promise = $q.resolve();
    this.initialized = false;
    this.authToken = null;
    this.janus = null;
    this.masterHandle = null;

    /*
     * Format:
     * {
     *   sessionId: {
     *     groupId: JanusStreamHandle,
     *     ...
     *   },
     *   ...
     * }
     */
    this.streamHandles = {};
    this.requestedStreams = new Set();
    this.hasFirstLocalIceCandidate = false;
    this.noCandidatesWarning = null;
    this.startTimestamp = Date.now();

    this.rtcConfigurationService.on('configuration', this._onRtcConfiguration);
    this.settingsService.on('videoQuality', this._onVideoQuality);
    this.tileService.on('draw', this._onTilesDraw);
  }


  get mode() {
    return 'sfu';
  }
  get roomId() {
    return this.meetingService.id;
  }
  get sessionId() {
    return this.userService.mySession.id;
  }


  _enqueue(...fns) {
    for(const fn of fns) {
      this.promise = this.promise.then(fn);
    }
    return this.promise;
  }



  /****************************
   * Manage main Janus object *
   ****************************/

  init() {
    if(this.initialized) {
      return;
    }
    this.initialized = true;
    this._createJanus();
  }


  destroy() {
    if(!this.initialized) {
      return;
    }
    this.initialized = false;
    this._destroyJanus();
    for(const stream of this.requestedStreams) {
      this._unrequest(stream);
    }
  }


  restart() {
    if(!this.initialized) {
      return;
    }
    this._destroyJanus();
    $timeout(() => this._createJanus(), 1000);
    if(Date.now() - this.startTimestamp < 10 * 1000) {
      // A quick restart most probably means we can't connect to Janus
      this._requestNewMediaServer();
    }
  }


  _createJanus() {
    if(
      this.janus
      || !this.initialized
      || this.userService.mySession.state === Session.State.ASLEEP
    ) {
      return;
    }

    this.startTimestamp = Date.now();

    const janus = new Janus();
    this.janus = janus;

    const promises = [
      this.rtcConfigurationService.get(),
      this._getAuthToken(),
    ];

    return $q.all(promises)
      .then(([rtcConfig, authToken]) => {
        if(janus !== this.janus) {
          return;
        }

        this.janus.on('connectionTimeout', () => {
          logger.info('Restarting Janus after connection timeout');
          this.restart();
        });
        // IMPROV JANUS: there may be errors which we can recover from
        this.janus.on('error', () => {
          logger.info('Restarting Janus after it reported an error');
          this.restart();
        });

        return this.janus.init({
          server: this.mediaserverUrl,
          token: authToken,
          iceServers: rtcConfig.iceServers,
          iceTransportPolicy: rtcConfig.iceTransportPolicy,
          ipv6: true,
          destroyOnUnload: false,
        });
      })
      .then(() => {
        if(janus !== this.janus) {
          return;
        }

        this.masterHandle = new JanusMaster(this.janus, this.roomId, this.sessionId);
        this.masterHandle.on(JanusHandle.State.DESTROYED, this._onMasterDestroyed);
        return this.masterHandle.init();
      })
      .then(() => {
        if(janus !== this.janus) {
          return;
        }

        this._destroyAllHandles();
        this._addAllStreamsToHandle();
      })
      .catch(error => {
        logger.error(error);
        this.notificationService.error(
          gettextCatalog.getString(
            'Failed to start audio/video communication. Please reload the page to continue.'
          ),
          { delay: -1 }
        );
      });
  }


  _destroyJanus() {
    if(!this.janus) {
      return;
    }

    logger.info('Destroying Janus');

    this.janus.destroy();
    this.janus = null;

    if(!this.initialized) {
      this._destroyAllHandles();
    }

    if(this.masterHandle) {
      this.masterHandle.off(JanusHandle.State.DESTROYED, this._onMasterDestroyed);
      this.masterHandle.destroy();
      this.masterHandle = null;
    }

    this.promise = $q.resolve();
  }


  _requestNewMediaServer() {
    logger.info('Requesting new media server');
    this.apiService.post(`meetings/${this.meetingService.id}/resetMediaServer`, {})
      .catch(error => logger.warn('Failed to request new media server', error));
  }


  _onMasterDestroyed() {
    // IMPROV JANUS: Not sure if this is necessary. Maybe restarting the master suffices.
    logger.info('Master handle destroyed, restarting...');
    this.restart();
  }

  _onUnrecoverableError() {
    logger.info('Encountered an unrecoverable error, restarting...');
    this.restart();
  }


  _onRtcConfiguration() {
    // If this.janus is not initialized, _createJanus() is currently executing, so we don't need
    // to restart.
    if(this.janus && this.janus.state !== Janus.State.NEW) {
      this.restart();
    }
  }



  /*************************
   * Manage the auth token *
   *************************/

  _getAuthToken() {
    if(this.authToken) {
      return $q.resolve(this.authToken);
    }

    return this.apiService.post('mediaserver-credentials/', {}, {
      maxRetries: Infinity,
    })
      .then(response => {
        this.authToken = response.data.token;
        return this.authToken;
      });
  }



  /******************
   * Manage handles *
   ******************/

  publish(stream) {
    this._request(stream);
  }

  subscribe(stream) {
    this._request(stream);
  }

  unpublish(stream) {
    this._unrequest(stream);
  }
  unsubscribe(stream) {
    this._unrequest(stream);
  }


  doIceRestart() {
    if(this.janus) {
      for(const sessionStreamHandles of Object.values(this.streamHandles)) {
        for(const streamHandle of Object.values(sessionStreamHandles)) {
          streamHandle.doIceRestart();
        }
      }
    } else {
      this._createJanus();
    }
  }



  _request(stream) {
    this.init();
    this.requestedStreams.add(stream);
    this._addStreamToHandle(stream);
  }


  _unrequest(stream) {
    this.requestedStreams.delete(stream);
    const handle = this._getActiveHandleForStream(stream);
    if(handle) {
      // If the handle only has this stream, it will destroy itself and trigger _onHandleDestroyed.
      handle.removeStream(stream);
    }
  }


  _addStreamToHandle(stream) {
    // If the masterHandle is currently initializing, _addStreamToHandle() will be called when
    // initializing is done
    if(!this.masterHandle || this.masterHandle.state !== JanusHandle.State.JOINED) {
      return;
    }

    if(!this.requestedStreams.has(stream) || this._getActiveHandleForStream(stream)) {
      return;
    }

    const handle = this._getOrCreateHandleForStream(stream);
    handle.addStream(stream);
    this._updateVideoBandwidth(stream);
  }


  _addAllStreamsToHandle() {
    for(const stream of this.requestedStreams) {
      this._addStreamToHandle(stream);
    }
  }


  _getSessionHandles(session) {
    if(!this.streamHandles[session.id]) {
      this.streamHandles[session.id] = {};
    }
    return this.streamHandles[session.id];
  }


  _getActiveHandleForStream(stream) {
    const sessionHandles = this._getSessionHandles(stream.session);
    const handle = sessionHandles[stream.groupId];
    if(!(handle && handle.streams[stream.kind] === stream)) {
      return null;
    }
    return handle;
  }


  _getOrCreateHandleForStream(stream) {
    const sessionHandles = this._getSessionHandles(stream.session);
    if(!sessionHandles[stream.groupId]) {
      const constructor = stream.isLocal ? JanusPublisher : JanusSubscriber;
      const handle = new constructor(
        this.settingsService,
        this.signalStrengthService,
        this.meetingService,

        this.janus,
        this.masterHandle,
        stream.session,
        stream.groupId
      );
      handle.on(JanusHandle.State.DESTROYED, this._onHandleDestroyed);
      handle.on('localIceCandidate', this._onLocalIceCandidate);
      handle.on('unrecoverableError', this._onUnrecoverableError);
      if(constructor === JanusSubscriber) {
        handle.on('track', this._onTrack);
        handle.on('connected', this._onConnected);
        handle.on('stats', this._onStats);
      }
      sessionHandles[stream.groupId] = handle;
    }

    return sessionHandles[stream.groupId];
  }


  _destroyAllHandles() {
    for(const sessionStreamHandles of Object.values(this.streamHandles)) {
      for(const streamHandle of Object.values(sessionStreamHandles)) {
        streamHandle.destroy();
      }
    }
  }


  _onHandleDestroyed(handle) {
    handle.off(JanusHandle.State.DESTROYED, this._onHandleDestroyed);
    handle.off('localIceCandidate', this._onLocalIceCandidate);
    handle.off('unrecoverableError', this._onUnrecoverableError);
    if(handle.constructor === JanusSubscriber) {
      handle.off('track', this._onTrack);
      handle.off('connected', this._onConnected);
      handle.off('stats', this._onStats);
    }

    const sessionHandles = this._getSessionHandles(handle.session);
    delete sessionHandles[handle.groupId];

    const requestedStreams = Object.values(handle.streams)
      .filter(stream => this.requestedStreams.has(stream));
    for(const stream of requestedStreams) {
      logger.withContext({ streamId: stream.id }).info('Recreate handle');
      // IMPROV JANUS: if a handle keeps on running into errors, maybe we should stop retrying at
      // some point.
      $timeout(() => this._addStreamToHandle(stream), 1000);
    }
  }


  _onTrack(handle, track) {
    const stream = handle.streams[track.kind];
    if(stream) {
      this.emit('track', stream, track);
      this._onConnected(handle);
    }
  }


  _onConnected(handle) {
    const streams = Object.values(handle.streams).filter(angular.identity);
    this.emit('connected', streams, handle.isConnected);
  }


  _onLocalIceCandidate(candidate) {
    if(candidate && candidate.candidate) {
      this.hasFirstLocalIceCandidate = true;
      if(this.noCandidatesWarning) {
        this.noCandidatesWarning.cancel();
        this.noCandidatesWarning = null;
      }

    } else if(!this.hasFirstLocalIceCandidate) {
      const text = gettextCatalog.getString(
        `
          A browser setting or extension is blocking audio/video communication.
          <a {{ url }}>Get help</a>.
        `,
        { url:
          `href="${this.siteService.getHelpArticle('iceCandidatesBlocked')}" target="_blank"`
        }
      );
      this.noCandidatesWarning = this.notificationService.warning(text, { delay: -1 });
    }
  }


  _onStats(_handle, stream, track, stats) {
    this.emit('stats', stream, track, stats);
  }



  /***************************
   * Manage stream bandwidth *
   ***************************/

  _onVideoQuality() {
    this._updateVideoBandwidths();
  }
  _onTilesDraw() {
    this._updateVideoBandwidths();
  }


  _updateVideoBandwidths() {
    if(!this.janus || this.janus.state === Janus.State.NEW) {
      return;
    }
    for(const stream of this.requestedStreams) {
      this._updateVideoBandwidth(stream);
    }
  }


  _updateVideoBandwidth(stream) {
    if(stream.kind !== 'video') {
      return;
    }

    const handle = this._getActiveHandleForStream(stream);
    if(!handle) {
      return;
    }

    const bandwidth = this._getVideoBandwidth(stream);
    handle.setVideoBandwidth(bandwidth);
  }


  _getVideoBandwidth(stream) {
    const resolution = this._getVideoResolution(stream);
    return this.settingsService.getSendBandwidth(resolution);
  }


  /**
   * Get the resolution at which a particular stream is displayed at the remote participants.
   *
   * In p2p we know the exact resolution at which a particular stream is displayed. In SFU mode
   * this depends on the window size of the remote participants. To determine the bitrate we work
   * with an assumed monitor size of 1080p.
   *
   * @param {Stream} stream
   * @returns
   */
  _getVideoResolution(stream) {
    if(this._isMaximizedStream(stream)) {
      const numStreams = this._getNumMaximizedStreams();
      return this._getMaximizedVideoResolution(numStreams);
    } else {
      return this._getMinimizedVideoResolution();
    }
  }


  _isMaximizedStream(stream) {
    const contentTile = this.tileService.activeContentTile;
    const streamTile = Object.values(this.tileService.tiles)
      .find(tile => tile.streams && tile.streams.video === stream);

    // This should never happen, but it might during some race condition, and we don't want
    // anything to break because of it.
    if(streamTile == null) {
      return false;
    }

    return (
      // Maximized content stream?
      (stream.type === StreamType.SCREEN || stream.type === StreamType.COBROWSE)
      && contentTile === streamTile

      // Maximized video stream?
      || stream.type === StreamType.VIDEO
      // Knocker tiles are always minimized
      && streamTile.type !== Tile.Type.KNOCKER
      && contentTile == null
    );
  }


  /**
   * Get the number of video streams that are currently being displayed in the central area of the
   * remote participants.
   */
  _getNumMaximizedStreams() {
    const contentTile = this.tileService.activeContentTile;
    if(contentTile) {
      return 1;
    }

    const maximizedVideoTiles = Object.values(this.tileService.tiles)
      .filter(tile => (
        tile.type === Tile.Type.USER
        && tile.streams.video
        && tile.streams.video.enabled
      ));
    // How many streams a remote participant sees depends on whether their own tile is maximized.
    // Since we don't know this, we always assume the "worst case", i.e. the *minimum* number of
    // maximized streams that may be displayed at one of the remote participants.
    // Some examples to clarify this calculation:
    // - A sends video, B and C do not. B and C both see 1 maximized tile.
    // - A sends video (own tile minimized), B send video (own tile maximized), C sends no video.
    //   A sees 1 maximized tile, B sees 2 maximized tiles, C sees 2 maximized tiles.
    // - A and B send video (own tile mimized), C sends video (own tile maximized). A and B see
    //   2 maximized tiles, C sees 3 maximized tiles.
    return Math.max(1, maximizedVideoTiles.length - 1);
  }


  _getMinimizedVideoResolution() {
    return 32e3;
  }

  _getMaximizedVideoResolution(numStreams) {
    // Keep this in sync with janus_orchestrator/orchestrator.py
    // When you open Vectera in a fullscreen browser window on a 1080p monitor, the central content
    // area will be around 1.5e6 pixels big. The division by `numStreams` is a crude approximation,
    // the true resolution of a stream depends on the window geometry.
    return 1.5e6 / numStreams;
  }
}
