import { Component } from '@angular/core';
import { Inject } from '@angular/core';


@Component({
  selector: 'smart-summary-notice-modal',
  templateUrl: './smart-summary-notice-modal.component.html',
})
export class SmartSummaryNoticeModalComponent {

  constructor(
    @Inject('endCallService') private endCallService,
  ) {
  }

  public leaveMeeting(): void {
    this.endCallService.endCall();
  }
}
