<div *ngIf="shouldConfirm">
  <div
    class="d-flex flex-row align-items-center"
    data-testid="formValueAndPencilIcon"
  >

    <div data-testid="contactFormName"> {{ value }}</div>

    <div
      class="btn btn--round btn--sm ml-2"

      i18n-matTooltip
      matTooltip="Edit"

      [dialog]="confirmDialog"
      dialogTitle="Confirm change"
      i18n-dialogTitle
      [dialogIcon]="{ name: 'edit' }"
      dialogConfirmText="I understand"
      i18n-dialogConfirmText
      dialogConfirmButtonClass="btn--warning"
      [dialogConfirmedCallback]="confirmChange.bind(this)"
      [dialogDisabled]="!shouldConfirm"
      data-testid="contactFormConfigNamePencilIcon"
    >
      <svg-icon class="btn__icon" [svgSize]="14" [svgName]="'edit'"></svg-icon>
    </div>
  </div>

  <ng-template #confirmDialog>
    <div [innerHTML]="dialogText"></div>
  </ng-template>
</div>

<ng-content *ngIf="!shouldConfirm"></ng-content>
