import './angularjs';
import './angularjsDowngradedComponents';

import { NgModule } from '@angular/core';
import { MeetingSidebarModule } from './meeting-room/sidebar/meeting-sidebar.module';
import { MeetingService } from './meeting-room/meeting.service';
import { upgradedProviders } from './angularjs-upgraded-providers';
import { AngularjsUpgradedMeetingModule } from './angularjs-upgraded/angularjs-upgraded.module';
import { ActiveIntervalService } from './meeting-room/activeInterval.service';
import { MeetingFooterModule } from './meeting-room/footer/meeting-footer.module';
import { StreamModule } from './meeting-room/stream/stream.module';
import { MeetingSmartSummaryModule } from './smart-summary/meeting-smart-summary.module';
import {
  EdgeWarningBannerComponent
} from './meeting-room/edge-warning-banner/edge-warning-banner.component';

@NgModule({
  imports: [
    AngularjsUpgradedMeetingModule,
  ],
  declarations: [
    EdgeWarningBannerComponent,
  ],
  exports: [
    MeetingSidebarModule,
    MeetingFooterModule,
    StreamModule,
    EdgeWarningBannerComponent,
    MeetingSmartSummaryModule,
  ],
  providers: [
    MeetingService,
    ActiveIntervalService,
    ...upgradedProviders
  ],
})
export class MeetingModule {}
