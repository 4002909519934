<div class="time-picker">
  <div class="time-picker__time-slots">
    <h3 class="mb-6" i18n>Choose a time slot</h3>
    <h4 class="mb-6">
      {{ selectedDate | readableDate: false: true }}
    </h4>

    <div class="time-picker__time-slot-container">
      <button
        *ngFor="let slot of timeSlots"
        class="time-slot-button btn btn--secondary"
        (click)="selectTimeSlot(slot)"
        data-testid="timeSlotButton"
      >
        {{ slot.start|localeTime }} — {{ slot.end|localeTime }}
      </button>
    </div>
  </div>


  <div i18n>
    Timezone: {{ timeZone }}
  </div>
</div>
