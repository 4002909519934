<div class="mt-4">
  <label class="label" i18n data-testid="currencyLabel">Currency</label>

  <div class="money-config__combo-box">
    <combo-box
      [(ngModel)]="currency"
      [options]="currencies"
      data-testid="currencyList"
    ></combo-box>
  </div>
</div>
