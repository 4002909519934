<div>
  <span i18n>
    Some users might experience audio issues during online meetings in the Edge browser. If this happens, we recommend to switch to another browser (Chrome, Firefox, Safari) to quickly solve the problem.
  </span>
  <span
    class="btn ml-2"
    (click)="setEdgeWarningSeen()"
    i18n
  >
    Hide
  </span>
</div>
