import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BASE_URL } from 'utils/base-url';
import { firstValueFrom, map } from 'rxjs';


export interface Member {
  id: string,
  fullName: string;
  dateJoined: Date;
  isAdmin: boolean;
  isActive: boolean,
  email: string,
  storageUsed: number,
  initials: string,
  organizationId: string,
}


export interface SerializedMember {
  id: string,
  fullName: string;
  dateJoined: string;
  isAdmin: boolean;
  isActive: boolean,
  email: string,
  storageUsed: number,
  initials: string,
  organizationId: string,
}


@Injectable()
export class MembersService {

  constructor(
    private http: HttpClient
  ) {
  }

  public getMembers(organizationId: string): Promise<Member[]> {
    const httpParams = (
      new HttpParams()
        .set('perPage', 'all')
        .set('include', 'storageUsed')
    );
    const observable = this.http.get<SerializedMember[]>(
      `${BASE_URL}/organizations/${organizationId}/members`, { params: httpParams }
    ).pipe(
      map((members: SerializedMember[]) => {
        return members.map(this.mapMemberToFrontEndModel);
      }),
    );
    return firstValueFrom(observable);
  }

  private mapMemberToFrontEndModel(member: SerializedMember): Member {
    return {
      ...member,
      dateJoined: new Date(member.dateJoined)
    };
  }

  public setIsActive(member: Member, isActive: boolean): Promise<Member> {
    return this.patchMember(member, { isActive: isActive });
  }

  public setIsAdmin(member: Member, isAdmin: boolean): Promise<Member> {
    return this.patchMember(member, { isAdmin: isAdmin } );
  }

  private patchMember(member: Member, data: Partial<SerializedMember>): Promise<Member> {
    const url = `${BASE_URL}/organizations/${member.organizationId}/members/${member.id}`;

    const observable = this.http.patch<SerializedMember>(url, data)
      .pipe(
        map(this.mapMemberToFrontEndModel)
      );
    return firstValueFrom(observable);
  }

  public deleteMember(member: Member): Promise<void> {
    const url = `${BASE_URL}/organizations/${member.organizationId}/members/${member.id}`;
    const observable = this.http.delete<void>(url);
    return firstValueFrom(observable);
  }
  public deleteUser(userId: string): Promise<void> {
    const url = `${BASE_URL}/users/${userId}`;
    const observable =  this.http.delete<void>(url);
    return firstValueFrom(observable);
  }
}
