<div *ngIf="!showInviteStatusScreen" data-testid="inviteModalScreen">
  <h4 class="d-flex align-items-center">
    <svg-icon class="icon" [svgName]="'link'"></svg-icon>
    <span class="ml-2" i18n>Via a permanent link</span>
  </h4>
  <p i18n>
    You can invite team members by sharing a permanent link with them.
    This link will allow them to join your team without needing an email invite.
  </p>
  <div
    *ngIf="!permanentInviteUrl"
    class="btn btn--primary"
    (click)="enablePermanentInviteUrl()"
    i18n

    data-testid="generateLinkButton"
  >
    Generate link
  </div>
  <div
    class="d-flex align-items-center justify-content-between"
    *ngIf="permanentInviteUrl"
  >
    <div class="invite-modal__invite-link">
      <input type="text" class="input" [value]="permanentInviteUrl" disabled data-testid="permanentInviteLink">
        <div
          [copyText]="permanentInviteUrl"
          class="btn btn--secondary"
          i18n

          data-testid="copyLinkButton"

        >
          Copy link
      </div>
    </div>
    <div
      class="btn btn--error"
      (click)="disablePermanentInviteUrl()"
      i18n

      data-testid="disableLinkButton"
    >
      Disable link
    </div>
  </div>

  <h4 class="d-flex align-items-center mt-8">
    <svg-icon class="icon" [svgName]="'mail'"></svg-icon>
    <span class="ml-2" i18n>Via a personal email invite</span>
  </h4>
  <p i18n>
    You can invite team members by sending them an invite email. You can add multiple email addresses.
  </p>

  <div *ngFor="let email of inviteEmails; let i=index" class="mt-2">
    <div class="d-flex align-items-center">
      <input
        class="input"
        type="email"
        [ngClass]="{
          'input--error': inviteEmails[i].inputValidationErrorMessage
        }"
        [(ngModel)]="inviteEmails[i].email"
        (ngModelChange)="onEmailChange(inviteEmails[i])"
        (blur)="onBlur(inviteEmails[i])"
        placeholder="Enter an email address..."
        i18n-placeholder

        data-testid="inviteEmailInput"
      />
      <div
        class="btn btn--square ml-2"
        (click)="removeEmail(i)"
        *ngIf="shouldShowDeleteOption(i)"
        data-testid="removeEmailButton"
      >
        <svg-icon class="icon" [svgName]="'trash'"></svg-icon>
      </div>
    </div>
    <div *ngIf="inviteEmails[i].inputValidationErrorMessage">
      <div class="text-error-400">{{ inviteEmails[i].inputValidationErrorMessage }}</div>
    </div>
  </div>

  <div class="mb-2 mt-2">
    <textarea
      [(ngModel)]="inviteMessage"
      (ngModelChange)="onInviteMessageChanged()"
      class="textarea"
      rows="7"
      maxlength="500"
    ></textarea>
    <div class="text-error-400 mt-1" *ngIf="inviteMessageErrorMessage">
      {{ inviteMessageErrorMessage }}
    </div>
  </div>

  <div
    class="btn btn--primary"
    (click)="sendAllInvites()"
    [ngClass]="{'disabled': !canSendInvites}"
    data-testid="sendInvitesButton"
  >
    <span *ngIf="numEmails > 1" i18n>
      Send invites
    </span>
    <span *ngIf="numEmails <= 1" i18n>
      Send invite
    </span>
  </div>
</div>

<div *ngIf="showInviteStatusScreen" data-testid="inviteModalSummaryScreen">
  <h4 class="d-flex align-items-center mb-10">
    <svg-icon class="icon" [svgName]="'mail'"></svg-icon>
    <span class="ml-2" i18n>Invite status</span>
  </h4>

  <div class="card card--no-padding overflow-x-auto">
    <table class="table table--hoverable align-middle">
      <thead>
        <tr>
          <th scope="col" i18n>Email address</th>
          <th scope="col" i18n>Invite status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let successMail of successMails" data-testid="successfullInviteEmail">
          <td>
              {{ successMail }}
          </td>

          <td>
            <svg-icon
              class="use-icon-color text-success-500"
              [svgName]="'checkmark_badged'"
              [svgStyle]="'filled'"

              data-testid="successfullInviteIcon"
            >
            </svg-icon>
          </td>
        </tr>
        <tr *ngFor="let failedMail of failedMails">
          <td>
              {{ failedMail }}
          </td>
          <td>
            <svg-icon
              class="use-icon-color text-error-500 mr-2"
              [svgName]="'close_badged'"
              [svgStyle]="'filled'"
            >
            </svg-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="d-flex justify-content-end mt-10">
    <div
      *ngIf="failedMails.length > 0"
      class="btn btn--secondary mr-2"
      (click)="backToInputScreenWithFailedMails()"
      i18n
    >
      Retry failed invites
    </div>

    <div
      class="btn btn--primary"
      i18n
      modalClose

      data-testid="inviteModalCloseButton"
    >
      Close
    </div>
  </div>
</div>
