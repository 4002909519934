import { User } from 'core/models/User';
import * as fields from 'utils/fields';


export class Member extends User {
  static get fields() {
    return Object.assign({}, User.fields, {
      dateJoined: new fields.DateTimeField(),
      isAdmin: new fields.BooleanField(),
      isActive: new fields.BooleanField(),
    });
  }

  static listPath(identifiers) {
    return `organizations/${identifiers.organizationId}/members`;
  }
}
