<select
  class="select text-teal-500"
  [ngModel]="value"
  (ngModelChange)="writeValue($event)"
  [ngModelOptions]="{standalone: true}"
  data-testid="dropdownButton"
>
  <option
    *ngIf="!question.required"
    [value]="null"
    i18n
    data-testid="dropdownListNotSelected"
  >
    Not selected
  </option>

  <option
    *ngFor="let option of options; trackBy:trackByValue"
    [value]="option.value"
    data-testid="dropdownListCustomOptions"
  >
    {{ option.label }}
  </option>
</select>
