<a
  *ngIf="href"
  [href]="href"
  [target]="target"
>
  <ng-container *ngTemplateOutlet="logo"></ng-container>
</a>

<ng-container *ngIf="!href">
  <ng-container *ngTemplateOutlet="logo"></ng-container>
</ng-container>


<ng-template #logo>
  <div (click)="trackLogoClick()">
    <app-logo
      *ngIf="!isCustom"
      [ngStyle]="style"
      data-testid="vecteraLogo"
    ></app-logo>
    <img
      *ngIf="isCustom"
      [src]="path"
      [ngStyle]="style"
    />
  </div>
</ng-template>
