import { Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  SmartSummaryNoticeModalComponent
} from './notice-modal/smart-summary-notice-modal.component';
import { ModalService } from '../../../../utils/static-src/utils/ui-components/modal';


@Injectable()
export class SmartSummaryService {


  constructor(
    private modalService: ModalService,
  ) {
    this.openNoticeModal();
  }

  openNoticeModal(): void {
    const componentPortal = new ComponentPortal(SmartSummaryNoticeModalComponent, null);
    this.modalService.open({
      componentPortal: componentPortal,
      modalClass: 'modal--sm',
      title: $localize `This meeting is being transcribed`,
      icon: {
        name: 'edit'
      }
    });
  }
}
