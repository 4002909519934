
import './angularjs.module';
import { NgModule } from '@angular/core';
import { MembersModule } from './members/members.module';
import { UtilsModule } from 'utils/utils.module';


@NgModule({
  imports: [
    UtilsModule,
  ],
  exports: [
    MembersModule,
  ],
  providers: [
  ],
})
export class OrganizationModule {}
