import { Component, Inject, OnInit } from '@angular/core';
import { RequestUserService } from 'utils/requestUser.service';
import { SiteService } from 'utils/site.service';
import { Member, MembersService } from '../../members/members.service';
import { Hint } from 'utils/angularjs/hints/Hint';
import { ViewOrganizationService,  } from 'utils/view-organization.service';
import { Organization } from '../../organization.service';
import { ChargebeeStatus } from 'subscription/models';
import {
  OrganizationInvitation,
  OrganizationInvitationService
} from '../../organization-invite.service';
import { logger } from 'utils/util';


@Component({
  selector: 'members-page',
  templateUrl: './members-page.component.html',
  styleUrls: ['./members-page.component.scss']
})
export class MembersPageComponent implements OnInit {

  public hint: Hint;
  public members: Member[] = [];
  public invites: OrganizationInvitation[] = [];
  public hasPermissionToManage: boolean;
  public deleteConfirmationFullName: string | null = null;
  public organization: Organization;

  public hasLoadedMembers = false;
  public hasLoadedInvites = false;

  public suspendedMembersCountMapping: {[count: string]: string} = {
    '=1': $localize `1 suspended member`,
    'other': $localize `# suspended members`,
  };
  public activeMembersCountMapping: {[count: string]: string} = {
    '=1': $localize `1 active member`,
    'other': $localize `# active members`,
  };
  public invitesCountMapping: {[count: string]: string} = {
    '=1': $localize `1 pending invite`,
    'other': $localize `# pending invites`,
  };


  constructor(
    @Inject('notificationService') private notificationService,
    @Inject('hintService') private hintService,
    private requestUserService: RequestUserService,
    public siteService: SiteService,
    private membersService: MembersService,
    private organizationInvitationService: OrganizationInvitationService,
    private viewOrganizationService: ViewOrganizationService,
  ) {
    this.hint = this.hintService.get('teamMembersSubscriptionInfo');
    this.hasPermissionToManage = ANGULAR_SCOPE.hasPermissionToManage;

    this.organization = this.viewOrganizationService.organization;
  }

  public ngOnInit(): void {
    this.loadMembers();
    this.loadInvites();
  }

  private async loadMembers(): Promise<void>  {
    try {
      const members = await this.membersService.getMembers(this.organization.id);
      this.members = members;
      this.sortMembers();
      this.hasLoadedMembers = true;
    } catch(error) {
      logger.error(error);
      this.notificationService.error(
        $localize `Something went wrong while loading the team members. Please try again later.`
      );
    }
  }

  private async loadInvites(): Promise<void> {
    try {
      const invites = await this.organizationInvitationService.getInvites(this.organization.id);
      this.invites = invites;
      this.sortInvites();
      this.hasLoadedInvites = true;
    } catch(error) {
      logger.error(error);
      this.notificationService.error(
        $localize `Something went wrong while loading the invites. Please try again later.`
      );
    }
  }

  private sortMembers(): void {
    this.members.sort((member1, member2) => {
      if(this.isMe(member1) && !this.isMe(member2)) {
        return -1;
      } else if(this.isMe(member2) && !this.isMe(member1)) {
        return 1;
      } else if(member1.isAdmin && !member2.isAdmin) {
        return -1;
      } else if(!member1.isAdmin && member2.isAdmin) {
        return 1;
      } else if(member1.isActive && !member2.isActive) {
        return -1;
      } else if(!member1.isActive && member2.isActive) {
        return 1;
      } else {
        return member1.fullName.localeCompare(member2.fullName);
      }
    });
  }

  private sortInvites(): void {
    this.invites.sort((invite1, invite2) => {
      return new Date(invite2.dateUpdated).getTime() - new Date(invite1.dateUpdated).getTime();
    });
  }

  public get inviteEmails(): string[] {
    return this.invites.map(invite => invite.email);
  }
  public get memberEmails(): string[] {
    return this.members.map(member => member.email);
  }

  public get hasPermissionToInvite(): boolean {
    return (
      this.hasPermissionToManage
      && !this.isSubscriptionQuantityLimited
      && this.isRequestUserVerified
    );
  }

  public get isSubscriptionQuantityLimited(): boolean {
    return (
      this.organization.subscription.quantityIsLimited
      && this.organization.subscription.limitedQuantity <= this.activeMembers.length
    );
  }

  public get isRequestUserVerified(): boolean {
    return this.requestUserService.user.emailIsVerified;
  }

  public get activeMembers(): Member[] {
    return this.members.filter((member: Member) => member.isActive);
  }

  public get suspendedMembers(): Member[] {
    return this.members.filter((member: Member) => !member.isActive);
  }


  /***********
   * ACTIONS *
   **********/

  public async setAdminRights(member: Member, isAdmin: boolean): Promise<void> {
    if(!this.hasPermissionToManage) {
      return;
    }

    try {
      const updatedMember = await this.membersService.setIsAdmin(member, isAdmin);
      member.isAdmin = updatedMember.isAdmin;
    } catch(error) {
      logger.error(error);
      this.notificationService.error(
        $localize `Something went wrong while updating admin rights. Please try again later.`);
    }
  }

  public async setActive(member: Member, isActive: boolean): Promise<void> {
    if(!this.hasPermissionToManage) {
      return;
    }
    try {
      const updatedMember = await this.membersService.setIsActive(member, isActive);
      member.isActive = updatedMember.isActive;
    } catch(error) {
      logger.error(error);
      this.notificationService.error(
        $localize `Something went wrong while updating the user's status. Please try again later.`
      );
    }
  }

  public async removeFromTeam(member: Member): Promise<void> {
    if(!this.hasPermissionToManage) {
      return;
    }
    const isMe = this.isMe(member);

    try {
      await this.membersService.deleteMember(member);
      const index = this.members.indexOf(member);
      this.members.splice(index, 1);

      if(isMe) {
        location.reload();
      }
    } catch(error) {
      logger.error(error);
      this.notificationService.error(
        $localize `Something went wrong while removing the user from the team. Please try again later.`  // eslint-disable-line max-len
      );
    }
  }


  public canPermanentlyDelete(member: Member): boolean {
    return this.deleteConfirmationFullName === member.fullName;
  }

  public async permanentlyDelete(member: Member): Promise<void> {
    if(!this.hasPermissionToManage) {
      return;
    }

    const isMe = this.isMe(member);

    try {
      await this.membersService.deleteUser(member.id);
      const index = this.members.indexOf(member);
      this.members.splice(index, 1);
      this.notificationService.success(
        $localize `User ${member.fullName} has been permanently deleted`
      );

      if(isMe) {
        location.reload();
      }
    } catch(error) {
      logger.error(error);
      this.notificationService.error(
        $localize `Something went wrong while deleting the user. Please try again later.`
      );
    }
  }

  public async deleteInvite(invite: OrganizationInvitation): Promise<void> {
    if(!this.hasPermissionToManage) {
      return;
    }
    try {
      await this.organizationInvitationService.deleteInvite(invite);
      const index = this.invites.indexOf(invite);
      this.invites.splice(index, 1);
    } catch(error) {
      logger.error(error);
      this.notificationService.error(
        $localize `Something went wrong while deleting the invite. Please try again later.`
      );
    }
  }

  public async resendInvite(invite: OrganizationInvitation): Promise<void> {
    try {
      await this.organizationInvitationService.resendInvite(invite);
      this.notificationService.success($localize `Invite has been resent to ${invite.email}`);
    } catch(error) {
      logger.error(error);
      this.notificationService.error(
        $localize `Something went wrong while resending the invite. Please try again later.`
      );
    }
  }

  public onInvitationsAdded(invites: OrganizationInvitation[]): void {
    this.invites.push(...invites);
  }


  /************
  * UTILITIES *
  ************/

  public get hasChargebeeSubscription(): boolean {
    return [
      ChargebeeStatus.FUTURE,
      ChargebeeStatus.ACTIVE,
      ChargebeeStatus.NON_RENEWING
    ].includes(this.organization.subscription.chargebeeStatus);
  }

  public get hasMediumStorageUsed(): boolean {
    return this.organization.storageUsed / this.organization.storageTotal > 0.7;
  }

  public get hasHighStorageUsed(): boolean {
    return this.organization.storageUsed / this.organization.storageTotal > 0.9;
  }


  public get isRequestUserAdmin(): boolean {
    if(!this.members) {
      return false;
    }
    const member = this.members.find(member => member.id === this.requestUserService.user.id);
    return !!member && !!member.isAdmin;
  }

  public isOnlyAdmin(member: Member): boolean {
    if(!member.isAdmin) {
      return false;
    }
    return this.members.filter(member => member.isAdmin).length === 1;
  }

  public isMe(member: Member): boolean {
    return member.id === this.requestUserService.user.id;
  }

  public canRevokeAdminRights(member: Member): boolean {
    return !!member.isAdmin && !this.isMe(member) && !this.isOnlyAdmin(member);
  }

  public getRevokeAdminDisabledTooltip(member: Member): string {
    if(member.isAdmin && this.isMe(member)) {
      return $localize `You can't revoke your own admin rights`;
    } else if(!this.isMe(member) && this.isOnlyAdmin(member)) {
      return $localize `You can't revoke the admin rights of the only admin in the team`;
    } else {
      return '';
    }
  }

  public get inviteTooltip(): string {
    if(this.isSubscriptionQuantityLimited) {
      return $localize `Your subscription quantity does not allow more invites`;
    } else if(!this.isRequestUserVerified) {
      return $localize `Verify your email address to invite more users`;
    } else {
      return '';
    }
  }

  public removeFromTeamTitle(member: Member): string {
    return this.isMe(member) ? $localize `Leave team` : $localize `Remove from team`;
  }
}
