import {
  Component,
  forwardRef,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { SimpleControlValueAccessor } from '../simple-control-value-accessor';


@Component({
  selector: 'datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent extends SimpleControlValueAccessor<Date | null> {
  @Input() placeholder = '';
  public invalidState = false;
  public shouldWarnUser = false;

  override writeValue(value: Date | null) {
    super.writeValue(value);
    this.invalidState = false;
    this.shouldWarnUser = false;
  }

  onMasterStateChanged(dateStr: string) {
    if(dateStr === '' || dateStr == null ) {
      this.writeValue(null);
    } else {
      const validDateStr = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
      if(validDateStr.test(dateStr)) {
        const date = dateStr ? new Date(dateStr) : null;
        if(date !== null && date.toString() !== 'Invalid Date') {
          this.writeValue(date);
          return;
        } else {
          // only warn user while they are inputting if the input is the correct format
          // but not a valid date
          this.shouldWarnUser = true;
        }
      }
      this.invalidState = true;
    }
  }

  restoreFocus(picker: MatDatepicker<Date>, $event) {
    if(picker.opened) {
      setTimeout(() => $event.target.focus());
    } else if(this.invalidState) {
      this.shouldWarnUser = true;
    }
  }

  onDatePickerClose() {
    if(this.invalidState) {
      this.shouldWarnUser = true;
    }
  }
}
