
<div class="d-flex align-items-center">
  <div class="tag" *ngIf="value">
    <span class="tag__text" data-testid="fileName">{{ value.name }}</span>
    <svg-icon
      class="tag__close"
      [svgName]="'close'"
      [svgSize]="14"
      (click)="clearFile()"
      data-testid="deleteFileIcon"
    ></svg-icon>
  </div>
  <div>
    <div
      *ngIf="!disabled && value == null"
      (pickFile)="setFile($event)"
      [pickFileTypes]="allowedTypes"
      class="btn btn--secondary"
    >
      {{ uploadText }}
    </div>
    <div
      *ngIf="!disabled && !value && allowedTypes && allowedTypes.length > 0"
      class="mt-2 text-muted"
    >
      {{ allowedTypesText }}
    </div>
  </div>
</div>
<settings-errors
  [settingsErrors]="errors"
></settings-errors>
