<div
  *ngIf="linkedItemWarning"
  class="field field--error mb-4"
>
  <field-icon [type]="'error'"></field-icon>
  {{ linkedItemWarning }}
</div>

<div class="compact-settings">
  <div class="compact-settings__header">
    <div class="text-h2">
      <span *ngIf="instance.id" i18n>Update your button</span>
      <span *ngIf="!instance.id" i18n>Create a button for your website</span>
    </div>
  </div>

  <div>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">

      <div class="compact-settings__section">
        <div class="compact-settings__section-title" i18n>Design your button</div>

        <div class="compact-settings__section-form">
          <div class="flex-1 mb--4">
            <!-- Colors -->
            <div class="p">
              <label for="buttonColor" class="text-muted" i18n>Button color</label>
              <color-picker
                id="buttonColor"
                formControlName="buttonColor"
                [defaultColor]="defaultButtonColor"
                data-testid="buttonColorPicker"
              ></color-picker>
            </div>

            <div class="p">
              <label for="textColor" class="text-muted" i18n>Button text color</label>
              <color-picker
                id="textColor"
                formControlName="textColor"
                [defaultColor]="defaultButtonTextColor"
                data-testid="textColorPicker"
              ></color-picker>
            </div>

            <!-- Text -->
            <div class="p">
              <label for="text" class="text-muted" i18n>Button text</label>
              <input
                id="text"
                class="input"
                formControlName="text"
                [withSettingsErrors]="errors.text"
                maxLength="{{ instance.fields.text.maxLength }}"
                character-count
                data-testid="textInput"
              >
            </div>

            <!-- Icon -->
            <div class="p">
              <label class="text-muted" i18n>Icon</label>

              <pro-feature-badge
                *ngIf="!hasPermissionToEditIcon"
                featureText="Upload your logo"
                i18n-featureText
              ></pro-feature-badge>

              <div
                *ngIf="hasPermissionToEditIcon"
                class="d-flex align-items-center justify-content-between"
              >
                <div class="flex-none">
                  <input
                    id="showIcon"
                    type="checkbox"
                    class="toggle"
                    formControlName="showIcon"
                  >
                  <label
                    for="showIcon"
                    class="toggle__label toggle__label--standalone"
                    data-testid="showIconToggle"
                  ></label>
                </div>
                <div *ngIf="hasPermissionToEditIcon && instance.showIcon">
                  <image-picker
                    formControlName="icon"
                    uploadText="Upload icon…"
                    i18n-uploadText
                    clearText="Clear icon"
                    i18n-clearText
                    [maxSize]="1 * 1000 * 1000"
                    [defaultImage]="defaultImage"
                    [withSettingsErrors]="errors.icon"
                    alignment="end"
                    class="d-flex flex-column"
                    data-testid="uploadIconButtonWidget"
                  ></image-picker>
                </div>
              </div>
            </div>
          </div>

          <button-preview
            class="contact-widget-settings__preview"
            [contactWidget]="instance"
          ></button-preview>
        </div>
      </div>

      <div class="compact-settings__section">
        <div>
          <div class="compact-settings__section-title" i18n>Set button behavior</div>

          <div >
            <input
              type="radio"
              class="radio"
              id="ContactWidgetBehaviour__MODAL"
              [value]="ContactWidgetBehaviour.MODAL"
              formControlName="behaviour"
              [ngClass]="{'disabled': !canSelectModalBehaviour }"
              [attr.disabled]="!canSelectModalBehaviour  ? true : null"
            >
            <label
              class="radio__label"
              for="ContactWidgetBehaviour__MODAL"
            >
              <span i18n>Open in a pop-up in the same browser tab</span>
              <help-tooltip
                *ngIf="!canSelectModalBehaviour"
                text="This option is not available when selecting a waiting room as the contact type."
                i18n-text
              >
              </help-tooltip>
            </label>

          </div>

          <div>
            <input
              type="radio"
              class="radio"
              id="ContactWidgetBehaviour__TAB"
              [value]="ContactWidgetBehaviour.TAB"
              formControlName="behaviour"
            >
            <label
              class="radio__label"
              for="ContactWidgetBehaviour__TAB"
              i18n
            >
              Open in a new browser tab
            </label>
          </div>
        </div>
      </div>


      <div class="compact-settings__section">
        <div class="compact-settings__section-title" i18n>Link contact type</div>
        <div class="contact-widget-settings__contact-types">
          <div
            *ngFor="let contactTypeGroup of contactTypeGroups"
            (click)="setContactTypeGroup(contactTypeGroup)"
            class="contact-widget-settings__contact-type form-group"
            [ngClass]="{
              'contact-widget-settings__contact-type--selected': isCurrentContactTypeGroup(contactTypeGroup)
            }"
            [attr.data-testid]="'contactType_' + contactTypeGroupConfigMap[contactTypeGroup].id"
          >
            <div class="contact-widget-settings__contact-type-checkbox">
              <div>
                <input
                type="checkbox"
                class="checkbox"
                id="contactType"
                [ngModel]="isCurrentContactTypeGroup(contactTypeGroup)"
                [ngModelOptions]="{standalone: true}"
              />
                <label for="contactType" class="checkbox__label" i18n></label>
              </div>
            </div>
            <svg-icon class="contact-widget-settings__contact-type-icon" [svgPath]="contactTypeGroupConfigMap[contactTypeGroup].svgPath"></svg-icon>
            <div>{{ contactTypeGroupConfigMap[contactTypeGroup].name }}</div>
          </div>
        </div>



        <div *ngIf="shouldShowSchedulingOnboarding" class="d-flex flex-column">
          <p i18n>Oh no, your calendar isn’t connected yet. We need to do this first because...where would we send the meetings to ;)</p>
          <div>
            <a class="btn btn--primary" (click)="startOnboarding()">
              <svg-icon class="btn__icon" [svgName]="'copy'"></svg-icon>
              <span i18n>Connect your calendar</span>
            </a>
          </div>
        </div>

        <div *ngIf="!this.hasDestinationOptions(contactTypeGroup) && contactTypeGroup ===  ContactTypeGroup.CONTACT_FORM" class="d-flex flex-column">
          <p i18n>You haven't created any Contact forms yet.</p>
          <div>
            <a class="btn btn--primary" href="{{ urlService.urls.contactFormCreate }}" data-testid="createContactFormButton">
              <svg-icon class="btn__icon" [svgName]="'forms'"></svg-icon>
              <span i18n>Create a Contact form</span>
            </a>
          </div>
        </div>


        <div *ngIf="this.hasDestinationOptions(this.contactTypeGroup) && !this.shouldShowSchedulingOnboarding">
          <div [cdkMenuTriggerFor]="dropdown" cdkMenuInheritWidth class="select" data-testid="linkedItemMenuTrigger">
            <linked-item-preview
              class="ml-3 overflow-hidden"
              [linkedItem]="selectedOption"
              [hideBadges]="true"
            ></linked-item-preview>
          </div>

          <ng-template #dropdown>
            <div class="dropdown dropdown--scrollable" cdkMenu data-testid="linkedItemMenu">
              <ng-container >
                <div
                  *ngFor="let option of filteredDestinationOptions; trackBy: trackByDestinationOption"
                  class="dropdown__list-item"
                  [class.dropdown__list-item--selected]="isSelectedOption(option)"
                  cdkMenuItem
                  (click)="changeOption(option)"
                  data-testid="linkedItemMenuItem"
                >
                  <linked-item-preview
                    class="overflow-hidden"
                    [linkedItem]="option"
                    [hideBadges]="true"
                  ></linked-item-preview>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </div>
      </div>

    </form>
</div>
