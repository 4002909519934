import { Injectable } from '@angular/core';
import {
  Organization,
  SerializedOrganization,
  OrganizationService
} from 'organization/organization.service';

@Injectable({
  providedIn: 'root',
})
export class ViewOrganizationService {
  public organization: Organization;

  constructor(
    private organizationService: OrganizationService,
  ) {
    const viewOrg = ANGULAR_SCOPE.viewOrganization as SerializedOrganization;
    this.organization = this.organizationService.mapOrganizationToFrontEndModel(viewOrg);
  }
}
