import { Injectable } from '@angular/core';
import { SchedulingState } from 'scheduling/variables';
import { PaymentOption } from 'scheduling/variables';
import { FormatStringArrayPipe } from 'utils/pipes/format-string-array.pipe';
import { RequestUserService } from 'utils/requestUser.service';
import { AppointmentTypeConfig } from 'scheduling/models/AppointmentTypeConfig';
import { AppointmentType } from 'scheduling/models/AppointmentType';
import { User } from 'core/models/User';


@Injectable()
export class AppointmentTypeConfigurationCheckService {
  constructor(
    private requestUserService: RequestUserService,
    private formatStringArrayPipe: FormatStringArrayPipe,
  ) {}

  requestUserConfigurationWarning() : string | void {
    const user = this.requestUserService.user;
    if(this.userIsNotBookable(user)) {
      // eslint-disable-next-line max-len
      return $localize `You have not set up your calendar connection. Until you set up this connection, your customers won't be able to book you.`;
    }
  }


  configurationIssueWarnings(appointmentType: AppointmentType) {
    let warnings: string[] = [];
    if(this.hasNoHostsLinked(appointmentType)) {
      warnings = warnings.concat(
        [
          $localize `This meeting type has no team member linked to it. This might be because team
members have been suspended from the team.
Customers can't book this meeting type until you add hosts and reactivate the meeting type`
        ]
      );
    }
    const memberWarnings = this.buildUnconfiguredMembersWarning(appointmentType);
    if(memberWarnings) {
      warnings = warnings.concat([memberWarnings]);
    }
    if(appointmentType.contactForm.isActive
      && this.requestUserService.user.organization.subscription.status === 'free'
      && !appointmentType.contactForm.questions.every(q => q.allowFree)) {
      // eslint-disable-next-line max-len
      warnings = warnings.concat([$localize `This Meeting type contains questions that are no longer available with your subscription plan`]);
    }
    warnings = warnings.concat(this.buildStripeConfigWarning(appointmentType));

    return warnings;
  }


  /***************
   * StripeConfig *
   ***************/

  get stripeConfig() {
    return this.requestUserService.user.organization.stripeConfig;
  }

  get hasStripeConnection() {
    return (
      this.stripeConfig?.isActive
      && !this.stripeConfig?.isExpired
    );
  }

  isMissingStripeConfig(appointmentType: AppointmentType) {
    return (
      appointmentType.paymentOption === PaymentOption.PAYMENT_NECESSARY
      && !this.hasStripeConnection
    );
  }

  buildStripeConfigWarning(appointmentType: AppointmentType): string[] {
    if(this.isMissingStripeConfig(appointmentType)) {
      return [
        $localize `This meeting type requires payment, but the Stripe integration is not set up.`
      ];
    } else {
      return [];
    }
  }

  /**********************
 * Unconfigured members *
 ***********************/

  hasNoHostsLinked(appointmentType: AppointmentType) : boolean {
    return appointmentType.appointmentTypeConfigs
      .filter(config => config.active).length === 0;
  }

  userIsNotBookable(user: User): boolean {
    return !(
      // Non-admins don't have permissions to read teammates' schedulingConfig
      user.schedulingConfig == null
      || user.schedulingConfig.state === SchedulingState.CONFIGURED
      || user.schedulingConfig.state === SchedulingState.BOOKABLE
    );
  }

  unconfiguredMembers(appointmentType: AppointmentType) : AppointmentTypeConfig[] {
    return appointmentType.appointmentTypeConfigs
      .filter(config => config.active)
      .map(config => config.user)
      .filter(user => this.userIsNotBookable(user));
  }

  buildUnconfiguredMembersWarning(appointmentType: AppointmentType): string | void {
    const unconfiguredMembers = this.unconfiguredMembers(appointmentType);
    if(unconfiguredMembers.length === 0) {
      return;
    }
    const memberNames = this.formatStringArrayPipe.transform(
      unconfiguredMembers.map(user => user.fullName)
    );
    let warning;
    if(unconfiguredMembers.length === 1) {
      // eslint-disable-next-line max-len
      warning =  $localize `${memberNames} has not set up their calendar connection. Customers won't be able to book this meeting type with them until this connection is set up.`;
    } else {
      // eslint-disable-next-line max-len
      warning = $localize `${memberNames} have not set up their calendar connection. Customers won't be able to book this meeting type with them until this connection is set up.`;
    }
    return warning;
  }
}
