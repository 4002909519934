import { Component } from '@angular/core';
import { MeetingService } from '../meeting.service';

@Component({
  selector: 'app-edge-warning-banner',
  templateUrl: './edge-warning-banner.component.html',
})
export class EdgeWarningBannerComponent {
  constructor(
    private meetingService: MeetingService
  ) {
  }

  setEdgeWarningSeen() {
    this.meetingService.setEdgeWarningSeen();
  }
}
