import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from 'utils/base-url';
import { firstValueFrom, map } from 'rxjs';
import { Subscription } from 'subscription/models';

type UrlString = string;

type Image = {
  url: UrlString,
  name: string,
  height: number,
}

export type WhitelabelSettings = {
  hasAddon: boolean,

  brandColor: string,
  customLogo: boolean,
  logo: Image,
  customFavicon: boolean,
  favicon: UrlString,
  customHomepage: boolean,
  homepage: UrlString,
  utmCampaign: string,

  customMeetingBackground: boolean,
  meetingBackground: string | null,
  customWaitingRoomBackground: boolean,
  waitingRoomBackground: string,

  customDomain: string | null,
  customDomainHeadBlock: string | null,
  customDomainRedirect: string | null,

  autoAddLogoWhiteboard: boolean,
  showPoweredBy: boolean,
};

export interface Organization {
  id: string,
  name: string,
  slug: string,
  logo: string | null,
  favicon: string | null,

  permanentInviteLinkEnabled: boolean,
  permanentInviteUrl: string,
  storageUsed: number,
  storageTotal: number,
  allowEditAppointments: boolean,
  allowEditMeetings: boolean,
  allowTeamAccessLevelOverride: boolean,
  liveMeetingRoomsOverviewEnabled: boolean,
  schedulingEnabled: boolean,
  termsAndConditions: string,
  showMembersPersonalInfo: boolean,
  subscription: Subscription,

  whitelabelSettings: WhitelabelSettings,
}

export interface SerializedOrganization {
  id: string,
  name: string,
  slug: string,
  logo: string | null,
  favicon: string | null,

  permanentInviteLinkEnabled: boolean,
  permanentInviteUrl: string,
  storageUsed: number,
  storageTotal: number,
  allowEditAppointments: boolean,
  allowEditMeetings: boolean,
  allowTeamAccessLevelOverride: boolean,
  liveMeetingRoomsOverviewEnabled: boolean,
  schedulingEnabled: boolean,
  termsAndConditions: string,
  showMembersPersonalInfo: boolean,
  subscription: Subscription,

  whitelabelSettings: WhitelabelSettings,
}


@Injectable({
  providedIn: 'root',
})
export class OrganizationService {

  constructor(
    private http: HttpClient
  ) {
  }

  public disablePermanentInviteUrl(organizationId: string): Promise<Organization> {
    return this.setPermanentInviteUrl(organizationId, false);
  }

  public enablePermanentInviteUrl(organizationId: string): Promise<Organization> {
    return this.setPermanentInviteUrl(organizationId, true);
  }

  private setPermanentInviteUrl(
    organizationId: string,
    isEnabled: boolean
  ): Promise<Organization> {
    const url = `${BASE_URL}/organizations/${organizationId}/`;
    const data = { permanentInviteLinkEnabled: isEnabled };

    const observable = this.http.patch<SerializedOrganization>(url, data)
      .pipe(
        map((serializedOrganization: SerializedOrganization) => {
          return this.mapOrganizationToFrontEndModel(serializedOrganization);
        }),
      );
    return firstValueFrom(observable);
  }

  public mapOrganizationToFrontEndModel(
    serializedOrganization: SerializedOrganization
  ): Organization {
    return serializedOrganization;
  }
}
