import { TimeDelta, errors } from 'utils/util';
import AccessLevel from 'utils/angularjs/accessLevel/AccessLevel';


export class Field {
  constructor(config = {}) {
    this.config = config;
    this.default = config.default != null ? config.default : null;
    this.readOnly = config.readOnly != null ? config.readOnly : false;

    this.fieldName = null;
    this.parent = null;
    this.value = null;

    /**
     * {Set} When setting a field value locally, we flag the field as "dirty". When sending the
     * instance representation to the backend (through a `create` or `update` call) only dirty
     * fields are passed to avoid "race conditions": overwriting a value that was updated by an
     * third party in the time between us fetching a value and writing it back to the API.
     */
    this.isDirty = false;
  }

  /**
   * Bind this field to a specific model instance.
   * @param {string} fieldName
   * @param {Model} parent The model instance
   */
  bind(fieldName, parent) {
    this.fieldName = fieldName;
    this.parent = parent;
    if(typeof this.default === 'function') {
      this.setValue(this.default(this));
    } else {
      this.setValue(this.default);
    }
  }

  get isBound() {
    return this.fieldName != null;
  }


  /**
   * Set the local value to a Javascript-native value.
   */
  setValue(value) {
    this.value = value;
  }


  /**
   * Set the local value to data that we received from the API.
   */
  setData(data) {
    const value = this.toInternalValue(data);
    this.setValue(value);
  }

  markDirty() {
    this.isDirty = true;
    if(this.isBound && this.parent.isBound) {
      // if the parent has parent, bubble up the isDirty flag.
      this.parent.parent.fields[this.parent.fieldName].markDirty();
    }
  }

  /**
   * Transform a value that we received from the API to a local, Javascript-native value.
   */
  toInternalValue(data) {
    return data;
  }


  /**
   * Transform the local, Javascript-native value to a representation that can be sent to the API.
   */
  toRepresentation() {
    return this.value;
  }

  equals(value) {
    return this.value === value;
  }
}


// You can use these fields in your models for documentation purposes, but they don't do any
// data conversions.
export {
  Field as NumberField,
  Field as BooleanField,
  Field as JSONField,
  Field as UUIDRelatedField,
  // A file value is a url, either a regular one (as received from the backend) or a data url
  // (created locally, can be used in POST/PATCH requests).
  Field as FileField,
};

export class StringField extends Field {
  constructor(config = {}) {
    super(config);

    this.maxLength = config.maxLength;
  }
}

export class DateTimeField extends Field {
  toRepresentation() {
    if(this.value == null) {
      return null;
    } else {
      return this.value.toISOString();
    }
  }

  toInternalValue(data) {
    return data ? new Date(data) : null;
  }
}


export class DateField extends Field {
  toRepresentation() {
    if(this.value == null) {
      return null;
    } else {
      // We need to account for timezones before converting to iso string and splitting.
      // Eg 2023-12-07 00:00:00+02:00 will be incorrectly converted by toISOString to
      // 2023-12-06T22:00:00Z.
      // We substract the timezoneoffset to get the correct date in UTC before converting to iso.
      return new Date(
        this.value.getTime() - (this.value.getTimezoneOffset() * 60000)
      ).toISOString().split('T')[0];
    }
  }

  toInternalValue(data) {
    return data ? new Date(data) : null;
  }
}


export class AccessLevelField extends Field {
  toRepresentation() {
    if(this.value == null) {
      return null;
    } else {
      return this.value.id;
    }
  }

  toInternalValue(data) {
    if(data == null) {
      return null;
    } else {
      return AccessLevel.getFromId(data);
    }
  }
}



export class ChoiceField extends Field {
  constructor(config = {}) {
    super(config);
    this.choices = new Set(config.choices);
    this.many = config.many != null ? config.many : false;
  }

  toInternalValue(data) {
    if(data == null) {
      return null;
    }
    if(this.many) {
      if(!Array.isArray(data)) {
        throw new errors.InvalidArgumentError(
          `Expected an array of choices, got ${data}`
        );
      }
      data.map(choice => this.validateSingleValue(choice));
    } else {
      this.validateSingleValue(data);
    }
    return data;
  }


  validateSingleValue(data) {
    if(!this.choices.has(data)) {
      throw new errors.InvalidArgumentError(
        `${data} is not a valid choice, choose from ${this.choices}`
      );
    }
  }
}



export class DurationField extends Field {
  toRepresentation() {
    if(this.value == null) {
      return null;
    } else {
      return this.value.toRepresentation();
    }
  }

  toInternalValue(data) {
    if(data == null) {
      return null;
    } else {
      return TimeDelta.toInternalValue(data);
    }
  }

  equals(value) {
    if(this.value != null && value != null) {
      return this.value.equals(value);
    } else {
      this.value == null && value == null;
    }
  }
}


/**
 * Most RelatedFields in the backend are readonly. When you want to change their value, you update
 * the corresponding ID field instead. E.g. you don't set `meeting.owner`, but `meeting.ownerId`.
 * In the frontend, this behaviour is supported by setting `config.withIdField = true` (which is
 * the default):
 * - When you create a `RelatedField` `field`, a corresponding `UUIDRelatedField` `fieldId` is
 *   created automatically.
 * - When you set the value of the `RelatedField` (e.g. `modelInstance.field = relatedInstance`),
 *   the value of the corresponding id field is updated automatically
 *   (`modelInstance.field = relatedInstance.id`).
 * - The value of the `field` field is never sent to the API, the value of `fieldId` is sent
 *   instead.
 *
 * A RelatedField with `config.withIdField = false` corresponds to a backend field that allows
 * nested updates, i.e. with a single request you can change both the model instance and a nested
 * model instance. For example `AppointmentType.apointmentTypeConfigs`. In this case, the full
 * value of the RelatedField is sent to the API (if it is dirty).
 */
export class RelatedField extends Field {
  constructor(config = {}) {
    const many = config.many != null ? config.many : false;
    if(config.readOnly == null) {
      config.readOnly = many;
    }
    super(config);

    this.Model = config.Model;
    this.many = many;
    this.withIdField = config.withIdField != null ? config.withIdField : true;
  }


  bind(fieldName, parent) {
    super.bind(fieldName, parent);
    this.bindChildren();
  }

  bindChildren() {
    if(this.value == null || !this.isBound) {
      return null;
    }
    const values = this.many ? this.value : [this.value];
    values.forEach(value => value.bind(this.fieldName, this.parent));
  }


  setValue(value) {
    super.setValue(value);
    if(!this.readOnly) {
      this.bindChildren();
    }
  }


  toRepresentation(onlyDirty) {
    if(this.value == null) {
      return null;
    } else if(this.many) {
      return this.value.map(valueItem => valueItem.toRepresentation(onlyDirty));
    } else {
      return this.value.toRepresentation(onlyDirty);
    }
  }

  toInternalValue(data) {
    if(data == null) {
      return null;
    } else if(this.many) {
      return data.map(dataItem => {
        return this.parent.modelFactory.createInstance(this.Model, { data: dataItem });
      });
    } else {
      return this.parent.modelFactory.createInstance(this.Model, { data: data });
    }
  }

  // eslint-disable-next-line no-unused-vars
  equals(value) {
    // TODO: This can be implemented by implementing an isEqual function on each model that is able
    // to handle this comparison.
    // E.g. 2 Relatedfields for users are equal if the user ids are equal.
    throw Error('Unable to compare RelatedFields to each other');
  }
}
