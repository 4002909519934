<div
  class="d-flex flex-column"
  [ngClass]="{'align-items-end': alignment === 'end'}"
>
  <div *ngIf="value == null" class="d-flex">
    <div *ngIf="defaultImageAsIcon" class="image-picker__icon-preview">
      <svg-icon [svgIcon]="defaultImageAsIcon"></svg-icon>
    </div>
    <div *ngIf="defaultImageAsUrl" class="image-picker__icon-preview image-picker__icon-preview--img">
      <div
        class="image-picker__icon-img"
        [style.background-image]="'url(\'' + defaultImageAsUrl + '\')'"
        data-testid="customIconPreview"
      ></div>
    </div>

    <div
      class="btn btn--secondary"
      (pickFile)="setFile($event)"
      [pickFileTypes]="allowedTypes"
      data-testid="uploadIconButton"
    >
      {{ uploadText }}
    </div>
    <div
      *ngIf="disabled"
      class="btn btn--secondary disabled"
    >
      {{ uploadText }}
    </div>
  </div>

  <div *ngIf="value != null" class="d-flex">
    <div class="image-picker__icon-preview image-picker__icon-preview--img">
      <div
        class="image-picker__icon-img"
        [style.background-image]="'url(\'' + value + '\')'"
        data-testid="customIconPreview"
      ></div>
    </div>

    <div
      class="btn btn--secondary"
      (click)="!disabled && clearFile()"
      [ngClass]="{ 'disabled': disabled }"
      data-testid="clearIconButton"
    >
      {{ clearText }}
    </div>
  </div>

  <div
    *ngIf="!disabled"
    class="mt-2 text-muted"
  >
    {{ allowedTypesText }}
  </div>
</div>
<settings-errors
  [settingsErrors]="errors"
  [ngClass]="{'text-right': alignment === 'end'}"
></settings-errors>
