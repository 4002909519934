import './angularjs.module';

/* eslint-disable max-len */
import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { MarkdownModule } from 'ngx-markdown';
import { UtilsModule } from 'utils/utils.module';
import { ContactFormModule } from 'contactForm/contact-form.module';
import { AppointmentTypeConfigComponent } from './manage/appointment-type-config/appointment-type-config.component';
import { AvailabilityPickerComponent } from './manage/appointment-type-config/availability-picker/availability-picker.component';
import { LocationPickerComponent } from './manage/appointment-type-config/location-picker/location-picker.component';
import { UnavailabilityPickerComponent } from './manage/appointment-type-config/unavailability-picker/unavailability-picker.component';
import {
  AppointmentTypeAvailabilityComponent,
  AppointmentTypeDetailsComponent,
  AppointmentTypePaymentsComponent,
  AppointmentTypeQuestionsComponent, AppointmentTypeRedirectingComponent,
  AppointmentTypeRemindersComponent, AppointmentTypeSummaryComponent, AppointmentTypeTemplatesComponent
} from './manage/appointment-type-config/segments';
import { AppointmentTypesComponent } from './manage/appointment-types/appointment-types.component';
import { AppointmentsComponent } from './manage/appointments/appointments.component';
import { AppointmentTypeInitComponent } from './manage/scheduling-onboarding-wizard/appointment-type-init/appointment-type-init.component';
import { CalendarConfigComponent } from './manage/scheduling-onboarding-wizard/calendar-config/calendar-config.component';
import { CalendarConnectComponent } from './manage/scheduling-onboarding-wizard/calendar-connect/calendar-connect.component';
import { SchedulingOnboardingWizardComponent } from './manage/scheduling-onboarding-wizard/scheduling-onboarding-wizard.component';
import { SchedulingOnboardingComponent } from './manage/scheduling-onboarding/scheduling-onboarding.component';
import { AppointmentTypeConfigurationCheckService } from './manage/appointment-types/appointment-type-configuration-check.service';
import { SchedulingConfigService } from './schedulingConfig.service';
import { MeetingRoomsMenuModule } from 'dashboard/home/meeting-rooms-menu/meeting-rooms-menu.module';
import { AppointmentsTableModule } from './manage/appointments-table/appointments-table.module';
import { AppointmentManageComponent } from './manage/appointment-manage/appointment-manage.component';
import { AppointmentDetailsComponent } from './ui/appointment-details/appointment-details.component';
import { BookingContainer } from './booking/containers/booking.container';
import { AppointmentTypesService } from './services/appointment-types.service';
import { HttpClientModule } from '@angular/common/http';
import { AppointmentTypeCardComponent } from './booking/components/appointment-type-card/appointment-type-card.component';
import { TimePickerComponent } from './booking/components/time-picker/time-picker.component';
import { DatePickerComponent } from './booking/components/date-picker/date-picker.component';
/* eslint-enable max-len */


@NgModule({
  declarations: [
    AppointmentsComponent,
    AppointmentTypeAvailabilityComponent,
    AppointmentTypeConfigComponent,
    AppointmentTypeDetailsComponent,
    AppointmentTypeInitComponent,
    AppointmentTypePaymentsComponent,
    AppointmentTypeQuestionsComponent,
    AppointmentTypeRedirectingComponent,
    AppointmentTypeRemindersComponent,
    AppointmentTypesComponent,
    AppointmentTypeSummaryComponent,
    AppointmentTypeTemplatesComponent,
    AvailabilityPickerComponent,
    UnavailabilityPickerComponent,
    CalendarConfigComponent,
    CalendarConnectComponent,
    LocationPickerComponent,
    SchedulingOnboardingComponent,
    SchedulingOnboardingWizardComponent,
    AppointmentManageComponent,
    AppointmentDetailsComponent,
    BookingContainer,
    AppointmentTypeCardComponent,
    TimePickerComponent,
    DatePickerComponent
  ],
  providers: [
    AppointmentTypeConfigurationCheckService,
    SchedulingConfigService,
    AppointmentTypesService
  ],
  imports: [
    AppointmentsTableModule,
    A11yModule,
    BrowserModule,
    FormsModule,
    MarkdownModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    UtilsModule,
    UpgradeModule,
    ContactFormModule,
    MeetingRoomsMenuModule,
    HttpClientModule
  ]
})
export class SchedulingModule { }
