import * as moment from 'moment';

export enum Weekday {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export enum StartOfWeek {
  Sunday = 0,
  Monday = 1
}

/*
 * DateTime util
 *
 * These utility functions internally use Moment.js and communicate to the outside with
 * JavaScript Date objects.
 */

export function initializeLocale(languageCode: string): void {
  moment.updateLocale(languageCode, {});
}

export function getLanguageCode(): string {
  const defaultBrowserLanguage = navigator.language;
  return window['REQUEST_USER'] && window['REQUEST_USER'].languageCode || defaultBrowserLanguage;
}

export function getLocaleTime(dateTime: Date, languageCode?: string): string {
  if (!languageCode) {
    languageCode = getLanguageCode();
  }

  let toLocaleString;
  try {
    // Not sure when this fails, but for some people this throws
    // "RangeError: Incorrect locale information provided"
    toLocaleString = dateTime.toLocaleTimeString(languageCode);
  } catch (e) {
    toLocaleString = dateTime.toLocaleTimeString();
  }

  // Remove special IE characters
  toLocaleString = toLocaleString.replace(/\u200E/g, '');
  toLocaleString = toLocaleString.replace(/(:\S{2}):\S{2}/, '$1');
  return toLocaleString;
}

export function toISODateString(date: Date): string {
  return moment(date).format('YYYY-MM-DD');
}

/**
  * @deprecated Use getFirstDayOfWeek(date: Date) instead
  */
export function getFirstDayOfWeekDeprecated(moment: moment.Moment): StartOfWeek {
  // Save the old locale because otherwise the availability headers in "edit appointment type"
  // are "ma. di. wo. ..." in dutch. Before translations, we best use english for these headers.
  const oldLocale = moment.locale();
  moment.locale(window['LANGUAGE_CODE']);
  const firstDayOfWeek = moment.localeData().firstDayOfWeek();
  moment.locale(oldLocale);
  return firstDayOfWeek;
}

export function getFirstDayOfWeek(): StartOfWeek {
  return moment.localeData().firstDayOfWeek();
}

export function getFirstDayOfMonth(month: number, year: number): Weekday {
  return moment({ month, year }).startOf('month').day();
}

export function getWeekdaysShort(): string[] {
  return moment.weekdaysShort(true);
}

export function isToday(date: Date): boolean {
  const today = moment();
  return today.isSame(date, 'day');
}

export function isTomorrow(date: Date): boolean {
  const tomorrow = moment().add(1, 'day');
  return tomorrow.isSame(date, 'day');
}

export function areSameDay(date1: Date, date2: Date): boolean {
  return moment(date1).isSame(date2, 'day');
}

export function getDaysInMonth(month: number, year: number): number {
  return moment({ month, year }).daysInMonth();
}

export function getMonthName(month: number): string {
  return moment.months()[month];
}
