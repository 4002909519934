import format from './format';
import * as object from './object';
import * as file from './file';

import * as Sentry from '@sentry/browser';
import * as SentryIntegrations from '@sentry/integrations';
export { Sentry };

const MAX_LOGS = 10000;

function getSentryUrl() {
  let path = '/_sentry/tunnel/';
  if(location.host === ANGULAR_SCOPE.teamleaderCloudSiteDomain) {
    path = '/_customer-meeting' + path;
  }
  return path;
}

const LOG_LEVELS = {
  trace: 'trace',
  debug: 'debug',
  info: 'info',
  log: 'log',
  warn: 'warning',
  error: 'error'
};
const SENTRY_LOG_LEVELS = new Set(['warn', 'error']);


class LogStorage {
  constructor() {
    this.logs = [];
    this.allowLogs = true;
  }

  add(log) {
    if(!this.allowLogs) {
      return;
    }

    this.logs.push(log);
    this.logs = this.logs.slice(Math.max(this.logs.length - MAX_LOGS, 0));
  }


  toString() {
    return this.logs.join('\n');
  }

  stop() {
    this.allowLogs = false;
  }

  download() {
    try {
      let title = format('Logs at %s', getFormattedDate(new Date()));
      let fileName = format('%s.txt', title);

      let blob = new Blob([this.toString()], { type: 'text/plain;charset=utf-8' });

      let localFile = file.fromBlob(blob, fileName);
      file.download(localFile, fileName);

    } catch(e) {
      log('error', {}, e.message);
    }
  }
}

let logStorage = new LogStorage();



class Message extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'Message';

    if(Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

function initializeLogger() {
  if(window.ANGULAR_SCOPE.sentryEnabled) {
    const SENTRY_OPTIONS = {
      dsn: 'https://f0b81da7eead4272ab88b1f176dc7048@sentry.io/190225',
      tunnel: getSentryUrl(),
      environment: ANGULAR_SCOPE.deployEnv,
      release: ANGULAR_SCOPE.deployVersion,
      autoSessionTracking: false,

      integrations: [new SentryIntegrations.Angular()],

      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'Can\'t find variable: ZiteReader',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks
        // @acdha). See http://stackoverflow.com/questions/4113268/
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Message from extension
        'The message port closed before a response was received.',
        // Some random error in Firefox, probably caused by an extension
        'NS_ERROR_NOT_INITIALIZED',
        // Lastpass
        'should_do_lastpass_here is not a function',
        // Firefox, related to video
        /^AbortError: The operation was aborted.$/,
        // See https://stackoverflow.com/questions/49384120
        /^ResizeObserver loop limit exceeded$/,
        /^ResizeObserver loop completed with undelivered notifications.$/,

        /^The play\(\) request was interrupted by a call to pause\(\)$/,
        // Chrome. It's unclear what causes these. It often happens after a network request.
        /^Timeout$/,

        // Chrome dev console
        /^Possible side-effect in debug-evaluate$/,

        // Server response when signed out
        /^Authentication credentials were not provided.$/,

        // Caused by Content-Security-Policy, not sure how to reproduce
        'call to Function() blocked by CSP',
      ],
      blacklistUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        // Mixpanel
        /mixpanel-js-utils/,
      ],
    };

    Sentry.init(SENTRY_OPTIONS);
    Sentry.setUser(window.USER);
  }
}

function isDOMError(value) {
  return Object.prototype.toString.call(value) === '[object DOMError]';
}

function isDOMException(value) {
  return Object.prototype.toString.call(value) === '[object DOMException]';
}

function isError(value) {
  return Object.prototype.toString.call(value) === '[object Error]';
}


/**
 * attempts to log the arguments to Sentry. If the primary argument is a string, it assumes it
 * is a formatstring with the remaining args being its arguments to fill in the specifiers.
 *
 * @param {string} level - the logging level: debug, info, log, warn or error
 * @param {Object.<any, any>} context - key-value pairs denoting additional contextual information
 * @param  {any} primaryArg - the primary argument to be logged, if a string assume this is a
 *   formatstring, if not assume this is an object to be printed
 * @param  {...any} [args] - the remaining arguments given to the log function, either to be
 *   inserted in the formatstring or (if the format string has no more specifiers)
 *   printed in their entirety
 */
function logToSentry(level, context, primaryArg, ...args) {
  let sentryMessage;

  if(typeof primaryArg === 'string' || args.length !== 0) {
    // squash all args into a single message
    sentryMessage = new Message(format(primaryArg, ...args));
  } else {
    // only one non-string argument: pass it to sentry untransformed
    sentryMessage = primaryArg;
  }

  Sentry.withScope(scope => {
    scope.setLevel(LOG_LEVELS[level]);
    object.forEach(context, (key, value) => {
      scope.setExtra(key, value);
    });

    Sentry.captureException(sentryMessage);
  });
}


/**
 * Entry point for logger.console.level calls. Attempts to log the arguments to console.
 * If the primary argument is a string, it assumes it is a formatstring with the remaining
 * args being its arguments to fill in the specifiers. If the primary argument is a
 * DOMError or DOMException, will handle these as special cases
 *
 * @param {string} level - the logging level: debug, info, log, warn or error
 * @param {Object.<any, any>} context - key-value pairs denoting additional contextual information
 * @param  {any} primaryArg - the primary argument to be logged, if a string assume this is a
 *   formatstring, if not assume this is an object to be printed
 * @param  {...any} [args] - the remaining arguments given to the log function, either to be
 *   inserted in the formatstring or (if the format string has no more specifiers)
 *   printed in their entirety
 */
function logToConsole(level, context, primaryArg, ...args) {
  let prefix = getPrefix(context);

  /* eslint-disable no-console */
  if(typeof primaryArg === 'string') {
    let consoleFormatStr = prefix + ' ' + primaryArg;
    console[level](consoleFormatStr, ...args);

  } else if(isDOMError(primaryArg)) {
    try {
      throw new Error('DOMError: ' + primaryArg.message);
    } catch(error) {
      console[level](prefix, error, ...args);
    }

  } else if(isDOMException(primaryArg)) {
    try {
      throw new Error('DOMException: ' + primaryArg.message);
    } catch(error) {
      console[level](prefix, error, ...args);
    }

  } else {
    console[level](prefix, primaryArg, ...args);
  }
  /* eslint-enable no-console */
}


/**
 * Entry point for logger.storage.level calls.  Attempts to log the arguments to
 * a file in local storage. If the primary argument is an Error object, will
 * handle this as special case
 *
 * @param {string} level - the logging level: debug, info, log, warn or error
 * @param {Object.<any, any>} context - key-value pairs denoting additional contextual information
 * @param  {any} primaryArg - the primary argument to be logged, if a string assume this is a
 *   formatstring, if not assume this is an object to be printed
 * @param  {...any} [args] - the remaining arguments given to the log function, either to be
 *   inserted in the formatstring or (if the format string has no more specifiers)
 *   printed in their entirety
 */
function logToStorage(level, context, primaryArg, ...args) {
  let levelContext = Object.assign({ level: level }, context);
  let prefix = getPrefix(levelContext);
  let tostore;

  // trying to print the Error object only shows the error.stack, firefox does not include
  // the actuall message in the stack so explicitely add it
  if(isError(primaryArg)) {
    tostore = prefix + ' ' + format('%s \n %s',  primaryArg.message, primaryArg.stack);
  } else {
    tostore = prefix + ' ' + format(primaryArg, ...args);
  }
  logStorage.add(tostore);
}

/**
 * creates a string containing the formatted date and the context key-value pairs:
 * [<date>; <key1>=<value1>; <key2>=<value2>,...]
 *
 * @param {Object.<any, any>} context - key-value pairs denoting additional contextual information
 */
function getPrefix(context) {
  let formattedDate = getFormattedDate(new Date());

  let prefixes = [
    formattedDate,
    ...Object.keys(context).map(key => format('%s=%s', key, context[key])),
  ];
  return '[' + prefixes.join('; ') + ']';
}

function getFormattedDate(date) {
  return format(
    '%s-%s-%s %s:%s:%s.%s',
    date.getFullYear(),
    (date.getMonth() + 1) .toString().padStart(2, '0'),
    date.getDate()        .toString().padStart(2, '0'),
    date.getHours()       .toString().padStart(2, '0'),
    date.getMinutes()     .toString().padStart(2, '0'),
    date.getSeconds()     .toString().padStart(2, '0'),
    date.getMilliseconds().toString().padStart(3, '0')
  );
}

/**
 * Entry point for logger.level calls. Will delegate the arguments
 * to the console and storage logger, and if enabled and on a high enough level, to the
 * Sentry logger.
 *
 * WARNING: abandon all typing, ye who enter here
 *
 * @param {string} level - the logging level: debug, info, log, warn or error
 * @param {Object.<any, any>} context - key-value pairs denoting additional contextual information
 * @param  {any} primaryArg - the primary argument to be logged
 * @param  {...any} [args] - the remaining arguments given to the log function
 */
function log(level, context, primaryArg, ...args) {
  logToConsole(level, context, primaryArg, ...args);
  logToStorage(level, context, primaryArg, ...args);
  if(window.ANGULAR_SCOPE.sentryEnabled && SENTRY_LOG_LEVELS.has(level)) {
    logToSentry(level, context, primaryArg, ...args);
  }
}


function download() {
  logStorage.download();
}

function stop() {
  logStorage.stop();
}


/**
 * creates a list of console loggers and storage loggers to log to, one for each log level.
 * Given a context object, will add these to the Sentry scope or (for logging to storage or
 * console) will prefix the key/value pairs to the logging string
 *
 * @param {Object.<any, any>} context - key-value pairs denoting additional contextual information
 */
function withContext(context) {
  let logger = {};
  logger.console = {};
  logger.storage = {};
  for(let level in LOG_LEVELS) {
    logger[level] = log.bind(null, level, context);
    logger.console[level] = logToConsole.bind(null, level, context);
    logger.storage[level] = logToStorage.bind(null, level, context);
  }
  return logger;
}

let logger = withContext({});
logger.withContext = withContext;
logger.download = download.bind();
logger.stop = stop.bind();
logger.initializeLogger = initializeLogger.bind();

/**
 * will by default export a logger object containing a list of "regular" loggers
 * that can be called by the standard logger.info(), logger.error,...
 *
 * this object also contains the same list of loggers for both storage and console
 * individually by respectively calling logger.console.level and logger.storage.level
 */
export default logger;
