/**
 * Deprecated service.
 * Some of our code still expects a viewOrganization to be the "old" organization model,
 * which extends from a general Model. It has some properties that are specific to the
 * ModelFactory setup (isDirty, update, create, ...). We don't want to upgrade all the modules
 * right now, so some code still needs to use the deprecated view organization service.
 */
import { Injectable, Inject } from '@angular/core';
import { Organization } from 'organization/models/Organization';


type SubscriptionStatusDeprecated = 'free' | 'trial' | 'active';
type UrlStringDeprecated = string;
type ImageDeprecated = {
  url: UrlStringDeprecated,
  name: string,
  height: number,
}

export type WhitelabelSettingsDeprecated = {
  hasAddon: boolean,

  brandColor: string,
  customLogo: boolean,
  logo: ImageDeprecated,
  customFavicon: boolean,
  favicon: UrlStringDeprecated,
  customHomepage: boolean,
  homepage: UrlStringDeprecated,
  utmCampaign: string,

  customMeetingBackground: boolean,
  meetingBackground: string | null,
  customWaitingRoomBackground: boolean,
  waitingRoomBackground: string,

  customDomain: string | null,
  customDomainHeadBlock: string | null,
  customDomainRedirect: string | null,

  autoAddLogoWhiteboard: boolean,
  showPoweredBy: boolean,
};

// There are more properties than this, but they are not used yet in the frontend. Feel free to
// add them when needed.
export type ViewOrganizationDeprecated = {
  id: string,
  name: string,
  slug: string,

  logo: string | null,
  favicon: string | null,

  allowEditAppointments: boolean,
  allowEditMeetings: boolean,
  allowTeamAccessLevelOverride: boolean,
  liveMeetingRoomsOverviewEnabled: boolean,
  permanentInviteLinkEnabled: boolean,
  schedulingEnabled: boolean,
  termsAndConditions: string,
  showMembersPersonalInfo: boolean,

  subscription: {
    status: SubscriptionStatusDeprecated,
  },

  whitelabelSettings: WhitelabelSettingsDeprecated,
}


@Injectable({
  providedIn: 'root',
})
export class ViewOrganizationDeprecatedService {
  public organization: ViewOrganizationDeprecated;

  constructor(
    @Inject('modelFactory') private modelFactory,
  ) {
    this.organization = modelFactory.createInstance(Organization, {
      data: ANGULAR_SCOPE.viewOrganization,
    });
  }
}
