import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs';
import { AppointmentLocation } from '../models/appointment-location';
import TimeDelta from '../../../../utils/static-src/utils/util/TimeDelta';
import { BASE_URL } from '../../../../utils/static-src/utils/base-url';

@Injectable()
export class AppointmentTypesService {
  constructor(private http: HttpClient) { }

  getUserPublicAppointmentTypes(userId: string, typeSlug: string | null):
    Observable<AppointmentType[]> {
    const httpParams = this.createHttpParamsFrom(typeSlug);

    return this.http.get<SerializedAppointmentType[]>(
      `${BASE_URL}/users/${userId}/appointmentTypes/public`, { params: httpParams }
    ).pipe(
      map((appointmentTypes: SerializedAppointmentType[]) => {
        return appointmentTypes.map(this.mapAppointmentTypeToFrontendModel);
      })
    );
  }

  private createHttpParamsFrom(typeSlug: string | null): HttpParams {
    let httpParams = new HttpParams();
    if(typeSlug) {
      httpParams = httpParams.append('type', typeSlug);
    }
    return httpParams;
  }

  private mapAppointmentTypeToFrontendModel(appointmentType: SerializedAppointmentType):
    AppointmentType {
    return {
      name: appointmentType.name,
      description: appointmentType.description,
      duration: TimeDelta.toInternalValue(appointmentType.duration),
      paymentAmount: appointmentType.paymentAmount,
      paymentCurrency: appointmentType.paymentCurrency,
      locations: appointmentType.locations
    };
  }

  getOrganisationPublicAppointmentTypes(organizationId: string, typeSlug: string | null):
    Observable<AppointmentType[]> {
    const httpParams = this.createHttpParamsFrom(typeSlug);

    return this.http.get<SerializedAppointmentType[]>(
      `${BASE_URL}/organizations/${organizationId}/appointmentTypes/public`, { params: httpParams }
    ).pipe(
      map((appointmentTypes: SerializedAppointmentType[]) => {
        return appointmentTypes.map(this.mapAppointmentTypeToFrontendModel);
      })
    );
  }

  getAppointmentHost(hostId: string): Observable<User> {
    return this.http.get<User>(`${BASE_URL}/users/${hostId}`);
  }
}

export interface AppointmentType {
  name: string;
  description: string;
  duration: TimeDelta;
  paymentAmount: number | null;
  paymentCurrency: string;
  locations: AppointmentLocation[];
}

export interface SerializedAppointmentType {
  name: string;
  description: string;
  duration: string;
  paymentAmount: number | null;
  paymentCurrency: string;
  locations: AppointmentLocation[];
}

export interface User {
  fullName: string;
}
