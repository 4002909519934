import { EventEmitter, getQueryParameter, logger, object, browser } from 'utils/util';
import { SiteService } from 'utils/site.service';
import { Injectable } from '@angular/core';
import MeetingSettings from './MeetingSettings';
import { DeviceType } from 'meeting/angularjs/shared/browser.service';
import { CookieService } from 'ngx-cookie-service';

const EDGE_WARNING_COOKIE_NAME = 'vectera-edge-warning-seen';
const EDGE_WARNING_COOKIE_EXPIRY = 7;

// This dict must be kept up to date with websocket-server/src/meetings.js
export enum MeetingState {
  EXITED = 'exited',
  KNOCKING = 'knocking',
  JOINED = 'joined',
  ACCESS_DENIED = 'kickedOut',
  ENDED = 'ended',
}

// this should eventually become a typescipt enum;
export const BednetLocation = Object.freeze({
  CLASSROOM: 'classroom',
  TEACHER: 'teacher',
  PUPIL: 'pupil',
});

@Injectable({
  providedIn: 'root',
})
export class MeetingService {
  private _state = MeetingState.EXITED;
  private _info = {};
  private hasBeenJoined = false;

  public eventEmitter: EventEmitter;
  public settings: MeetingSettings;
  public id;
  public ownerId;

  /**
   * Using Object.defineProperty, a bunch of class properties are added on this object.
   *
   * This list tries to catalogue those properties.
   *
   * The list is preliminary: in an AJS context no typing is happening, so any mistakes will only
   * become apparent when the respective origin is upgraded.
   *
   * At that time, an effort should also be made to give each property the correct type
   */
  public isTemplate;
  public isDeactivated;
  public isTeamWaitingRoom;
  public isPersonalWaitingRoom;
  public oneTimeMeetingDate;
  public key;
  public recordings;
  public size;
  public owner;
  public publicAccessLevel;
  public teamAccessLevel;
  public hardLimitNumEventsReached;
  public bookingLink;
  public ownerIsActive;
  public hostPresent;
  public adminpulseRelation;
  public bednetView = DeviceType.PHONE;
  public edgeWarningCookieSet = false;

  constructor(
    private siteService: SiteService,
    private cookieService: CookieService,
  ) {
    this.eventEmitter = EventEmitter.setup(this, ['state', 'redirect', 'bednet-view'], true);

    this.settings = new MeetingSettings(this);

    // Backwards compatibility: whitelabel settings used to be communicated through the meeting
    // serializer, but are now communicated through the organization serializer.
    if(ANGULAR_SCOPE.meeting) {
      ANGULAR_SCOPE.meeting.settings.whitelabel = (
        ANGULAR_SCOPE.viewOrganization.whitelabelSettings
      );
      this.setInfo(ANGULAR_SCOPE.meeting);
    }

    // We don't want to continuously fetch the cookie because that might cause a sudden banner
    // appearance if the cookie expires during the meeting. We only check the cookie once
    // on page load.
    this.edgeWarningCookieSet = this.cookieService.get(EDGE_WARNING_COOKIE_NAME) !== '';
  }


  get MeetingState() {
    return MeetingState;
  }
  get BednetLocation() {
    return BednetLocation;
  }


  public setInfo(info) {
    if(!info) {
      return;
    }
    // If the meeting has changed, we are dealing with a redirect.
    if(this.id && info.id !== this.id) {
      if(this.hasBeenJoined) {
        // To avoid issues with previously joined meetings, we reload the page
        // if the session was previously joined.
        window.location.href = info.url;
      } else {
        try {
          window.history.pushState(null, '', info.url);
        } catch(error) {
          // This might happen if the new url is not on the same domain as the original meeting.
          // This can be the case if a custom domain was set before the redirect.
          // Just reload on the new url.
          window.location.href = info.url;
        }
      }
      this.eventEmitter.emit('redirect');
    }

    object.forEach(info, (key, value) => {
      if(key === 'settings') {
        this.settings.update(value);
        return;
      }

      if(!this._info.hasOwnProperty(key)) {
        this._info[key] = null;
        Object.defineProperty(this, key, {
          get: this.getSyncedProperty.bind(this, key),
        });
      }

      if(value !== this._info[key]) {
        this._info[key] = value;
        this.eventEmitter.emit(key, value);
      }
    });
  }

  public userIsOwner(user) {
    return this.ownerId === user.id;
  }

  private getSyncedProperty(key) {
    return this._info[key];
  }


  get state() {
    return this._state;
  }
  set state(state) {
    this.setState(state);
  }
  /**
   * Set the current state of the meeting room.
   * @param {State} state
   * @param {Session.ExitReason} exitReason - If state is ENDED: the exitReason that should be
   *  given to the local meeting session.
   */
  public setState(state, exitReason?) {
    if(state === this._state) {
      return;
    }

    logger.info('Set meeting state from %s to %s', this.state, state);
    if(state === MeetingState.JOINED) {
      this.hasBeenJoined = true;
    }
    this._state = state;
    this.eventEmitter.emit('state', state, exitReason);
    this.eventEmitter.emit(state, exitReason);
  }


  public getHomepage(utmSource) {
    let urlString = this.settings.whitelabel.homepage;

    if(utmSource && !this.settings.whitelabel.customHomepage) {
      const url = new URL(urlString);
      url.searchParams.append('utm_medium', 'referral');
      url.searchParams.append('utm_source', utmSource);
      url.searchParams.append('utm_campaign', this.settings.whitelabel.utmCampaign);
      urlString = url.toString();
    }

    return urlString;
  }

  get bednetLocation() {
    const location = getQueryParameter('bednet');
    if(Object.values(BednetLocation).includes(location)) {
      return location;
    } else {
      return null;
    }
  }

  bednetSwitchView() {
    if(this.bednetView === DeviceType.PHONE) {
      this.bednetView = DeviceType.DESKTOP;
    } else if(this.bednetView === DeviceType.DESKTOP) {
      this.bednetView = DeviceType.PHONE;
    }
    this.eventEmitter.emit('bednet-view');
  }

  get showEdgeWarning(): boolean {
    return (
      !this.edgeWarningCookieSet
      && !this.isPersonalWaitingRoom
      && !this.isTeamWaitingRoom
      && !this.isTemplate
      && browser.isEdge()
    );
  }

  setEdgeWarningSeen() {
    this.edgeWarningCookieSet = true;
    this.cookieService.set(EDGE_WARNING_COOKIE_NAME, 'true', EDGE_WARNING_COOKIE_EXPIRY);
  }
}
