export { default as ColorManager } from './ColorManager';
export { default as EventEmitter } from './EventEmitter';
export { default as DragListener } from './DragListener';
export { default as errors } from './errors';
export { default as format } from './format';
export { default as logger } from './logger';
export { default as normalizeWheel } from './normalizeWheel';
export { default as readableDate } from './readableDate';
export { default as Rect } from './Rect';
export { default as TimeDelta } from './TimeDelta';
export { Time } from './Time';
export { TimeRange } from './TimeRange';
export { DateRange } from './date-range';

import * as array from './array';
import * as browser from './browser';
import * as color from './color';
import * as file from './file';
import * as interval from './interval';
import * as object from './object';
import * as raf from './raf';
import * as set from './set';
import * as storage from './storage';
import * as string from './string';
import * as dateTime from './date-time';
import * as debounceThrottle from './debounceThrottle';
import * as siteColor from './siteColor';

const debounce = debounceThrottle.debounce;
const throttle = debounceThrottle.throttle;

export {
  identity,
  noop,
} from './functional';

export {
  array,
  browser,
  color,
  file,
  interval,
  object,
  raf,
  set,
  storage,
  string,
  dateTime,
  debounce,
  throttle,
  siteColor,
};

export {
  getQueryParameter,
  getBooleanAttribute,
  platform,
  bind,
  getPixelRatio,
  isValidEmail,
  getTimestring,
  getFixedAncestor,
  runAt,
  memoize,
  requestAnimationFrameWithInactiveSupport,
} from './misc';

export {
  validateEnum,
  validateEnumWithDefault,
  validateEnumList,
} from './enum';


export function assert(condition: boolean, message?: string): asserts condition {
  if(!condition) {
    throw new Error('Assertion failed' + (message != null ? `: ${message}` : '.'));
  }
}
