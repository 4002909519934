<div class="contact-form-success__main">
  <svg-icon
    *ngIf="!customization.successImage"
    [svgPath]="'contactForm__highFive'"
    data-testid="highFiveImage"
  ></svg-icon>

  <img
    *ngIf="customization.successImage"
    [src]="customization.successImage"
    class="contact-form-success__image"
    data-testid="contactFormSuccessImage"
  >

  <p class="text-pre-wrap" data-testid="contactFormSuccessText">{{ customization.successText }}</p>

  <div *ngIf="!hasFreeSubscription">
    <div class="contact-form-success__files"
         *ngIf="hasDownloadFile">
      <div class="contact-form-success__download-btn"
           data-testid="contactFormSuccesDownloadFileButton"
           (click)="downloadFile($event)">{{ customization.downloadButtonText }}</div>
    </div>
    <div class="contact-form-success__files"
         *ngIf="hasDownloadLink">
      <a [href]="downloadLink"
        target="_blank"
        data-testid="contactFormSuccesLinkButton"
        class="contact-form-success__download-btn">{{ customization.downloadButtonText }}
      </a>

    </div>
  </div>
</div>



<div class="contact-form-success__powered-by">
  <powered-by
    *ngIf="shouldShowPoweredBy"
    [utmSource]="'contact_form'"
    [segmentSource]="'contact_form'"
    [tint]="customization.poweredByTint"
    data-testid="contactFormsSuccessPagePoweredByBadge"
  ></powered-by>
</div>
