<preview [hideTitle]="buttonPreviewHideTitle">
  <div class="m-6 d-flex align-items-center justify-content-center flex-1">
    <div
      class="button-preview__button"
      [style.--button-preview-button-color]="buttonColor"
      [style.--button-preview-button-hover-color]="buttonHoverColor"
      [style.--button-preview-text-color]="textColor"
      data-testid="buttonPreview"
    >
      <div
        class="button-preview__button-icon button-preview__button-icon--default"
        *ngIf="contactWidget.showIcon && contactWidget.icon == null"
        data-testid="buttonPreviewDefaultIcon"
      >
        <app-logo
          [logoVariant]="'icon_nopadding'"
          [logoMonochrome]="true"
          data-testid="vecteraLogowidget"
        ></app-logo>
      </div>

      <img
        class="button-preview__button-icon"
        *ngIf="contactWidget.showIcon && contactWidget.icon != null"
        [attr.src]="contactWidget.icon"
        data-testid="buttonPreviewCustomIcon"
      />

      <div
        class="button-preview__button-label"
        data-testid="buttonPreviewLabel"
      >
        {{ contactWidget.text }}
      </div>
    </div>
  </div>
</preview>
