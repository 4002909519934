import { InjectionToken } from '@angular/core';
import { Session } from 'meeting/angularjs/main/users/Session';
import { User } from 'meeting/angularjs/main/users/User';


// to be able to inject with the `inject()` function we need to use
// an InjectionToken instead of a string as the provider key
export const UserService = new InjectionToken<UserServiceType>('userService');

// AngularJS services use untyped properties, which typescript doesn't like.
// Explicitely type some of these properties here as a stopgap measure, but this should be fixed
// when a Service is upgraded.
export type UserServiceType = {
  me: User;
  mySession: Session;
  aliveHosts(): User[];
  amOnlyHost: boolean;
}

export const SETTINGS_SERVICE_TOKEN = new InjectionToken<string>('modelFactory');
export const FOCUS_SERVICE_TOKEN = new InjectionToken<string>('focusService');
export const SEGMENTATION_SETTINGS_SERVICE_TOKEN =
  new InjectionToken<string>('segmentationSettingsService');
export const VIDEO_COMPATIBLE_SERVICE_TOKEN = new InjectionToken<string>('videoCompatibleService');

export const upgradedProviders = [
  {
    provide: 'meetingEndedService',
    useFactory: (i: any) => i.get('meetingEndedService'),
    deps: ['$injector']
  },

  {
    provide: 'meetingBroadcastService',
    useFactory: (i: any) => i.get('meetingBroadcastService'),
    deps: ['$injector']
  },

  {
    provide: 'recorderService',
    useFactory: (i: any) => i.get('recorderService'),
    deps: ['$injector']
  },

  {
    provide: 'userService',
    useFactory: (i: any) => i.get('userService'),
    deps: ['$injector']
  },

  {
    provide: 'mediaDeviceService',
    useFactory: (i: any) => i.get('mediaDeviceService'),
    deps: ['$injector']
  },
  {
    provide: UserService,
    useFactory: (i: any) => i.get('userService'),
    deps: ['$injector']
  },

  {
    provide: 'settingsService',
    useFactory: (i: any) => i.get('settingsService'),
    deps: ['$injector']
  },
  {
    provide: SETTINGS_SERVICE_TOKEN,
    useFactory: (i: any) => i.get('settingsService'),
    deps: ['$injector']
  },

  {
    provide: 'focusService',
    useFactory: (i: any) => i.get('focusService'),
    deps: ['$injector']
  },
  {
    provide: FOCUS_SERVICE_TOKEN,
    useFactory: (i: any) => i.get('focusService'),
    deps: ['$injector']
  },

  {
    provide: 'segmentationSettingsService',
    useFactory: (i: any) => i.get('segmentationSettingsService'),
    deps: ['$injector']
  },
  {
    provide: SEGMENTATION_SETTINGS_SERVICE_TOKEN,
    useFactory: (i: any) => i.get('segmentationSettingsService'),
    deps: ['$injector']
  },

  {
    provide: 'videoCompatibleService',
    useFactory: (i: any) => i.get('videoCompatibleService'),
    deps: ['$injector']
  },
  {
    provide: VIDEO_COMPATIBLE_SERVICE_TOKEN,
    useFactory: (i: any) => i.get('videoCompatibleService'),
    deps: ['$injector']
  },
  {
    provide: 'endCallService',
    useFactory: (i: any) => i.get('endCallService'),
    deps: ['$injector']
  },

];
