<div class="attachment-input">
  <div
    class="attachment-input__area"
    [class.attachment-input__area--dragover]="dragover"
    (dragover)="onDragOver($event)"
    (dragenter)="onDragEnter($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
    data-testid="uploadFileArea"
  >
    <div class="attachment-input__icon-wrapper">
      <svg-icon [svgPath]="'illustrations__60x60_upload_static'"></svg-icon>
    </div>

    <div i18n class="text-teal-500">Drag and drop your file right here or <a (click)="fileInput.click()">upload a file</a></div>
    <div class="text-muted" *ngIf="maxFileSize > 0">{{ maxFileSizeText }}</div>
  </div>


  <div
    class="btn btn--secondary w-full d-sm-none"
    (click)="fileInput.click()"
    i18n
  >
    <svg-icon class="btn__icon" [svgName]="'cloud_upload'"></svg-icon>
    <span>Upload a file</span>
  </div>

  <input
    type="file"
    class="d-none"
    #fileInput
    (change)="onFileChange($event)"
    data-testid="uploadFileInput"
  >


  <div class="attachment-input__files" *ngIf="value && value.length > 0">
    <div
      *ngFor="let item of value"
      class="tag attachment-input__file"
    >
      <span data-testid="fileName" class="tag__text" >{{ item[0] }}</span>
      <svg-icon
        data-testid="deleteFileIcon"
        class="tag__close"
        [svgName]="'close'"
        [svgSize]="14"
        (click)="remove(item)"

      ></svg-icon>
    </div>
  </div>
</div>
