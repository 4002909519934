import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BASE_URL } from 'utils/base-url';
import { firstValueFrom, map } from 'rxjs';


export interface OrganizationInvitation {
  organizationId: string,
  id: string,
  dateUpdated: Date;
  email: string;
}

export interface SerializedOrganizationInvitation {
  organizationId: string,
  id: string,
  dateUpdated: string;
  email: string;
}


@Injectable({
  providedIn: 'root',
})
export class OrganizationInvitationService {

  constructor(
    private http: HttpClient
  ) {
  }

  public getInvites(organizationId: string): Promise<OrganizationInvitation[]> {
    const httpParams = (
      new HttpParams()
        .set('perPage', 'all')
    );
    const observable = this.http.get<SerializedOrganizationInvitation[]>(
      `${BASE_URL}/organizations/${organizationId}/invites`, { params: httpParams }
    ).pipe(
      map((invites: SerializedOrganizationInvitation[]) => {
        return invites.map(this.mapInvitesToFrontEndModel);
      })
    );
    return firstValueFrom(observable);
  }

  public sendInvite(
    organizationId: string,
    message: string,
    email: string
  ): Promise<OrganizationInvitation> {
    const data = {
      message: message,
      email: email,
    };
    const observable = this.http.post<SerializedOrganizationInvitation>(
      `${BASE_URL}/organizations/${organizationId}/invites`, data,
    ).pipe(
      map(this.mapInvitesToFrontEndModel)
    );
    return firstValueFrom(observable);
  }

  public resendInvite(invite: OrganizationInvitation): Promise<OrganizationInvitation> {
    const observable = this.http.patch<SerializedOrganizationInvitation>(
      `${BASE_URL}/organizations/${invite.organizationId}/invites/${invite.id}/resend`,
      {}
    ).pipe(
      map(this.mapInvitesToFrontEndModel)
    );
    return firstValueFrom(observable);
  }

  public deleteInvite(invite: OrganizationInvitation): Promise<void> {
    const observable = this.http.delete<void>(
      `${BASE_URL}/organizations/${invite.organizationId}/invites/${invite.id}`
    );

    return firstValueFrom(observable);
  }

  private mapInvitesToFrontEndModel(
    organizationInvitation: SerializedOrganizationInvitation
  ): OrganizationInvitation {
    return {
      ...organizationInvitation,
      dateUpdated: new Date(organizationInvitation.dateUpdated),
    };
  }
}
