import { Inject, Injectable } from '@angular/core';
import { Member } from 'organization/models/Member';
import { User } from 'core/models/User';
import { ComboBoxOption } from 'utils/form-elements/dropdowns/combo-box/combo-box.component';
import { RequestUserService } from 'utils/requestUser.service';

@Injectable({
  providedIn: 'root',
})
export class TeamMembersService {
  private fetchPromise? : Promise<Member[]>;
  private results? : Member[];

  constructor(
    @Inject('modelFactory') private modelFactory,
    private requestUserService: RequestUserService,
  ) {
  }

  async get(forceFetch = false, params = {}): Promise<Member[]> {
    const apiConfig = {
      params: params
    };
    if(forceFetch || !this.fetchPromise ) {
      const promise = this.modelFactory.list({
        model: Member,
        identifiers: { organizationId: this.requestUserService.user.organizationId },
        include: ['schedulingConfig'],
      }, apiConfig).then(({ data: teamMembers }) => {
        this.results = teamMembers;
        return this.results;
      });
      this.fetchPromise = promise;
      return promise;
    } else {
      return this.fetchPromise;
    }
  }


  buildComboBoxOptions(teamMembers, includeNone = true) {
    const options: ComboBoxOption[] = [];
    teamMembers.filter(member => member.isActive).forEach(member => {
      const isMe = member.id === this.requestUserService.user.id;

      options.push({
        label: isMe ? $localize `${member.fullName} (You)` : member.fullName,
        value: member,
      });
    });
    if(includeNone) {
      options.push({
        label: $localize `:When no team member is selected in a dropdown:None`,
        value: undefined,
      });
    }
    return options;
  }

  userIsActiveTeamMember(teamMembers, user: User): boolean {
    return teamMembers.filter(member => member.id === user.id && member.isActive).length > 0;
  }
}
