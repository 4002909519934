import { dateTime } from 'utils/util';

export class DateRange {
  constructor(
    public from: Date,
    public to: Date,
  ) { }

  toRepresentation() {
    return [
      dateTime.toISODateString(this.from),
      dateTime.toISODateString(this.to),
    ];
  }

  static toInternalValue(from: string, to: string): DateRange {
    const fromDate = from ? new Date(from) : null;
    const toDate = to ? new Date(to) : null;

    if(
      !fromDate
      || !toDate
      || fromDate.toString() === 'Invalid Date'
      || toDate.toString() === 'Invalid Date'
    ) {
      throw Error('Could not parse DateRange input');
    } else {
      return new DateRange(fromDate, toDate);
    }
  }
}
