import { HttpClientXsrfModule } from '@angular/common/http';


const deployEnv = ANGULAR_SCOPE.deployEnv;

function getCsrfCookieName() {
  let csrfCookieName = 'vectera-csrftoken';
  if(deployEnv && deployEnv !== 'prod') {
    csrfCookieName += '-' + deployEnv;
  }
  return csrfCookieName;
}

function getCsrfHeaderName() {
  let csrfHeaderName = 'X-Vectera-Csrftoken';
  if(deployEnv && deployEnv !== 'prod') {
    csrfHeaderName += '-' + deployEnv[0].toUpperCase() + deployEnv.slice(1);
  }
  return csrfHeaderName;
}

export const CSRFModule = HttpClientXsrfModule.withOptions({
  cookieName: getCsrfCookieName(),
  headerName: getCsrfHeaderName(),
});
