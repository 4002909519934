import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import {
  HubSpotWidgetComponent
} from './meeting-room/sidebar/hubspot-widget/hubspot-widget.component';
import {
  AdminPulseWidgetComponent
} from './meeting-room/sidebar/adminpulse-widget/adminpulse-widget.component';
import {
  IntegrationSyncComponent
} from './meeting-room/footer/notes/integration-sync/integration-sync.component';
import { PrivateNotesComponent } from './meeting-room/footer/notes/private-notes.component';
import { MeetingService } from './meeting-room/meeting.service';
import { ActiveIntervalService } from './meeting-room/activeInterval.service';
import { PrivateNotesService } from './meeting-room/footer/notes/private-notes.service';
// eslint-disable-next-line max-len
import { IntegrationSyncService } from './meeting-room/footer/notes/integration-sync/integration-sync.service';
import { AudioContextService } from './meeting-room/stream/audioContext.service';
import { StreamService } from './meeting-room/stream/stream.service';
import { StreamFactory } from './meeting-room/stream/streamFactory.service';
import {
  EdgeWarningBannerComponent
} from './meeting-room/edge-warning-banner/edge-warning-banner.component';
import { SmartSummaryService } from './smart-summary/smart-summary.service';

angular
  .module('meeting')

  .directive(
    'privateNotes',
    downgradeComponent({
      component: PrivateNotesComponent,
      inputs: ['shouldBeTranslucent', 'shouldCollapseButton']
    })
  )
  .directive(
    'hubspotWidget',
    downgradeComponent({
      component: HubSpotWidgetComponent,
    })
  )
  .directive(
    'adminpulseWidget',
    downgradeComponent({
      component: AdminPulseWidgetComponent,
    })
  )
  .directive(
    'integrationSync',
    downgradeComponent({
      component: IntegrationSyncComponent,
    })
  )
  .directive(
    'edgeWarningBanner',
    downgradeComponent({
      component: EdgeWarningBannerComponent,
    })
  )

  .service(
    'activeIntervalService',
    downgradeInjectable(ActiveIntervalService)
  )
  .service(
    'meetingService',
    downgradeInjectable(MeetingService)
  )
  .service(
    'privateNotesService',
    downgradeInjectable(PrivateNotesService)
  )
  .service(
    'integrationSyncService',
    downgradeInjectable(IntegrationSyncService)
  )

  .service(
    'audioContextService',
    downgradeInjectable(AudioContextService)
  )
  .service(
    'streamService',
    downgradeInjectable(StreamService)
  )
  .service(
    'streamFactory',
    downgradeInjectable(StreamFactory)
  )
  .service(
    'smartSummaryService',
    downgradeInjectable(SmartSummaryService)
  )
;
